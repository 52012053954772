import { useReadQuery } from '@apollo/client'
import { useRouteLoaderData } from 'react-router-typesafe'
import { BlocksTable } from '../../blocks/BlocksTable'
import { coinPageLoader } from '../CoinPage'
import { BlocksCardLayout } from './BlocksCardLayout'

export function BlocksCard() {
  const queryRef = useRouteLoaderData<typeof coinPageLoader>('coin_page')
  const { data } = useReadQuery(queryRef)
  if (!data.coinBySymbol) return null
  return (
    <BlocksCardLayout
      content={
        <BlocksTable
          query={data.coinBySymbol.blocks}
          bip44_symbol={data.coinBySymbol.bip44_symbol}
          limit={5}
        />
      }
    />
  )
}
