import { useLazyQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { FragmentType, graphql, getFragmentData } from '../../gql'
import { TimelineTransaction } from './TimelineTransaction'

const AddressTransactionsFragment = graphql(`
  fragment AddressTransactionsFragment on PaginatedIAddressTransactionResponse {
    items {
      txN
      ...AddressTransactionFragment
    }
    hasMore
  }
`)

const LoadMoreQuery = graphql(`
  query LoadMoreAddressTransactionsQuery(
    $coin: String!
    $address: String!
    $height: Int!
    $cursor: AddressTransactionCursor
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      address(address: $address) {
        address
        addressBlock(height: $height) {
          address {
            address
          }
          height
          transactions(direction: ASC, cursor: $cursor) {
            ...AddressTransactionsFragment
          }
        }
      }
    }
  }
`)

export function TimelineTransactions(props: {
  height: number
  address: string
  query: FragmentType<typeof AddressTransactionsFragment>
}) {
  const { address, coin } = useParams()
  if (!address) throw new Error('Param address is required')
  if (!coin) throw new Error('Param coin is required')
  const res = getFragmentData(AddressTransactionsFragment, props.query)
  const [query, { loading }] = useLazyQuery(LoadMoreQuery, {
    fetchPolicy: 'network-only'
  })
  return (
    <Stack gap={0.5} alignItems="flex-start">
      {res.items.map((row) => {
        return <TimelineTransaction key={row.txN} query={row} />
      })}
      {res.hasMore && (
        <LoadingButton
          loading={loading}
          variant="outlined"
          size="small"
          onClick={() => {
            query({
              variables: {
                coin,
                address,
                height: props.height,
                cursor: { txN: res.items[res.items.length - 1].txN }
              }
            })
          }}
        >
          Load more
        </LoadingButton>
      )}
    </Stack>
  )
}
