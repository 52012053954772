import { ReactComponent as BitcoinIcon } from 'cryptocurrency-icons/svg/color/btc.svg'
import { ReactComponent as DashIcon } from 'cryptocurrency-icons/svg/color/dash.svg'
import { ReactComponent as LitecoiIcon } from 'cryptocurrency-icons/svg/color/ltc.svg'
import { ReactComponent as BitcoinCashIcon } from 'cryptocurrency-icons/svg/color/bch.svg'
import { ReactComponent as DogecoinIcon } from 'cryptocurrency-icons/svg/color/doge.svg'

export const CoinSymbolToName: Record<string, string> = {
  BTC: 'Bitcoin',
  LTC: 'Litecoin',
  DASH: 'Dash',
  DOGE: 'Dogecoin',
  BCH: 'Bitcoin Cash'
}

export const coinIcons: Record<string, typeof BitcoinIcon | typeof DashIcon | typeof LitecoiIcon> =
  {
    BTC: BitcoinIcon,
    DASH: DashIcon,
    LTC: LitecoiIcon,
    BCH: BitcoinCashIcon,
    DOGE: DogecoinIcon
  }
