import dayjs from 'dayjs'
import { useMemo } from 'react'

export const dateToDayjs = (date?: string) => {
  if (date) {
    const res = dayjs(date)
    if (res.isValid()) return res
  }
  return dayjs().add(-1, 'day')
}
export const useDayjsDate = (date?: string) => {
  return useMemo(() => dateToDayjs(date), [date])
}

export const dayjsToQueryVariable = (date: dayjs.Dayjs) => date.toISOString().split('T')[0]
