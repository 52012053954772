import { Box, BoxProps } from '@mui/material'
import { fixedForwardRef } from './fixedForwardRef'

type TableElementInnerProps<C extends React.ElementType> = BoxProps<C, { component?: C }> & {
  'aria-label': string
}

function TableElementInner<C extends React.ElementType>(
  props: TableElementInnerProps<C>,
  ref: React.Ref<C>
) {
  return <Box role="table" ref={ref} {...props} />
}

export const TableElement = fixedForwardRef(TableElementInner)
