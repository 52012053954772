import { Suspense, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem
} from '@mui/material'
import { MoreVert, SwapHoriz } from '@mui/icons-material'
import { TransactionOutputs } from './TransactionOutputs'
import { TransactionInputs } from './TransactionInputs'
import { TransactionDetails, TransactionDetailsSkeleton } from './TransactionDetails'
import { useLoaderData } from 'react-router-typesafe'
import { transactionLoader } from './transactionLoader'
import { useReadQuery } from '@apollo/client'
import { CoinSymbolToName } from '../util/CoinUtil'
import RouterLink from '../RouterLink'

interface Props {
  coin: string
  txid: string
  address?: string
  highlightedInput?: number
  highlightedOutput?: number
}

function TransactionCardHeader({ txid }: { txid: string }) {
  const { coin } = useParams()
  if (!coin) throw new Error('Param coin is required')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <CardHeader
      avatar={
        <Avatar aria-label="Transaction">
          <SwapHoriz />
        </Avatar>
      }
      title={`Transaction`}
      subheader={txid}
      subheaderTypographyProps={{ sx: { overflowWrap: 'anywhere' } }}
      action={
        <>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
            <MenuItem component={RouterLink} to={`/${coin.toLowerCase()}/graph?txid=${txid}`}>
              Open graph visualization
            </MenuItem>
          </Menu>
        </>
      }
    ></CardHeader>
  )
}

function TransactionPageLoaded({
  coin,
  txid,
  highlightedInput,
  highlightedOutput,
  address
}: Props) {
  const queryRef = useLoaderData<typeof transactionLoader>()
  const { data } = useReadQuery(queryRef)
  const { coinBySymbol } = data
  if (!coinBySymbol) throw new Error('Coin not found')
  const { transaction } = coinBySymbol
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <TransactionCardHeader txid={txid} />
            <CardContent>
              {transaction ? (
                <TransactionDetails transaction={transaction} />
              ) : (
                <Alert severity="error">Transaction not found</Alert>
              )}
            </CardContent>
          </Card>
        </Grid>
        {transaction && (
          <Grid item xs={12} sm={6}>
            <TransactionInputs
              coin={coin}
              highlightedInput={highlightedInput}
              address={address}
              inputs={transaction}
            />
          </Grid>
        )}
        {transaction && (
          <Grid item xs={12} sm={6}>
            <TransactionOutputs
              highlightedOutput={highlightedOutput}
              address={address}
              outputs={transaction}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

function TransactionPageSkeleton({ txid }: { txid: string }) {
  return (
    <Box>
      <Grid container spacing={1} columns={12}>
        <Grid item xs={12}>
          <Card>
            <TransactionCardHeader txid={txid} />
            <CardContent>
              <TransactionDetailsSkeleton />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title={`Inputs`} titleTypographyProps={{ variant: 'h6' }}></CardHeader>
            <CardContent>
              <LinearProgress
                sx={{ width: '30%', maxWidth: 120, mx: 'auto', my: 5 }}
                color="inherit"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader title={`Outputs`} titleTypographyProps={{ variant: 'h6' }}></CardHeader>
            <CardContent>
              <LinearProgress
                sx={{ width: '30%', maxWidth: 120, mx: 'auto', my: 5 }}
                color="inherit"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

function TransactionPage() {
  const { coin, txid } = useParams()
  const [search] = useSearchParams()
  const address = search.get('address') || undefined
  const searchInput = search.get('input')
  const searchOutput = search.get('output')
  const highlightedInput = searchInput ? Number.parseInt(searchInput) : undefined
  const highlightedOutput = searchOutput ? Number.parseInt(searchOutput) : undefined

  if (!coin) throw new Error('Param coin is required')
  if (!txid) throw new Error('Param txid is required')
  useEffect(() => {
    const oldTitle = document.title
    document.title = `${CoinSymbolToName[coin.toUpperCase()]} Transaction ${txid}`
    return () => {
      document.title = oldTitle
    }
  }, [coin, txid])
  return (
    <Suspense fallback={<TransactionPageSkeleton txid={txid} />}>
      <TransactionPageLoaded
        coin={coin}
        txid={txid}
        address={address}
        highlightedInput={highlightedInput}
        highlightedOutput={highlightedOutput}
      />
    </Suspense>
  )
}

export default TransactionPage
