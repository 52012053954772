import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'
import { dateToDayjs, dayjsToQueryVariable } from '../util/DateUtils'

const topLosersQuery = graphql(`
  query topLosers(
    $coin: String!
    $date: String!
    $topLosersCursor: AddressBalanceChangeCursor
    $topLosersLimit: Int
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      date(date: $date) {
        topLosers(direction: ASC, cursor: $topLosersCursor, limit: $topLosersLimit) {
          ...TopLosersTableFragment
        }
      }
    }
  }
`)

export const topLosersLoader = (({ params: { coin, date } }) => {
  if (!coin) throw new Error('Param coin is required')
  return preloadQuery(topLosersQuery, {
    variables: { coin, date: dayjsToQueryVariable(dateToDayjs(date)) }
  })
}) satisfies LoaderFunction
