import { useState } from 'react'
import { FragmentType, graphql, getFragmentData } from '../gql'
import { Link, useParams } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { IconButton, TableCell, TableRow, Chip, Typography } from '@mui/material'
import RouterLink from '../RouterLink'

const outputFragment = graphql(`
  fragment OutputFragment on ITransactionOutput {
    ...ScriptPubkeyFragment
    n
    value
    spendingTxid
    spendingIndex
  }
`)

const scriptPubkeyFragment = graphql(`
  fragment ScriptPubkeyFragment on ITransactionOutput {
    scriptPubKey {
      address {
        address
      }
      asm
    }
  }
`)

export function ScriptPubkeyCell({
  output,
  coin,
  highlightedAddress
}: {
  output: FragmentType<typeof scriptPubkeyFragment>
  coin: string
  highlightedAddress?: string
}) {
  const { scriptPubKey } = getFragmentData(scriptPubkeyFragment, output)
  const [scriptOpen, setScriptOpen] = useState(false)
  return (
    <TableCell>
      {scriptPubKey.address?.address ? (
        <RouterLink
          to={`/${coin}/address/${scriptPubKey.address.address}`}
          sx={{
            fontWeight: scriptPubKey.address.address === highlightedAddress ? 'bold' : 'normal',
            overflowWrap: 'anywhere'
          }}
          relative="path"
        >
          {scriptPubKey.address.address}
        </RouterLink>
      ) : (
        <>
          {scriptOpen ? (
            <>
              <Typography sx={{ overflowWrap: 'anywhere' }}>{scriptPubKey.asm}</Typography>{' '}
              <Chip onClick={() => setScriptOpen(false)} label="Hide script" size="small" />
            </>
          ) : (
            <>
              Unable to decode address{' '}
              <Chip onClick={(e) => setScriptOpen(true)} label="Show script" size="small" />
            </>
          )}
        </>
      )}
    </TableCell>
  )
}

export function TransactionOutput(props: {
  query: FragmentType<typeof outputFragment>
  highlightedAddress?: string
  highlightedOutput?: number
}) {
  const { coin } = useParams()
  if (!coin) throw new Error('Param coin is required')
  const output = getFragmentData(outputFragment, props.query)
  return (
    <TableRow selected={output.n === props.highlightedOutput}>
      <ScriptPubkeyCell output={output} highlightedAddress={props.highlightedAddress} coin={coin} />
      <TableCell>
        {output.value} {coin.toUpperCase()}
      </TableCell>
      <TableCell sx={{ border: 'none', padding: 0 }}>
        {output.spendingTxid && (
          <IconButton
            component={Link}
            to={{ pathname: `../${output.spendingTxid}`, search: `input=${output.spendingIndex}` }}
            relative="path"
            aria-label="Navigate to spending input"
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}
