import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'

const coinQuery = graphql(`
  query coin($coin: String!) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      name
    }
  }
`)

export const coinLoader = (({ params: { coin } }) => {
  if (!coin) throw new Error('Param coin is required')
  return preloadQuery(coinQuery, { variables: { coin } })
}) satisfies LoaderFunction
