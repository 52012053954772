import { useLazyQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { FragmentType, graphql, getFragmentData } from '../gql'
import { TransactionInputRow } from './TransactionInput'

const LoadMoreQuery = graphql(`
  query LoadMoreTransactionInputs($coin: String!, $txid: String!, $cursor: TransactionInputCursor) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      transaction(txid: $txid) {
        txid
        inputs(direction: ASC, limit: 100, cursor: $cursor) {
          items {
            spendingIndex
            ...TransactionInputFragment
          }
          hasMore
        }
      }
    }
  }
`)

const TransactionInputsFragment = graphql(`
  fragment TransactionInputsFragment on ITransaction {
    inputs(direction: ASC, limit: 100, cursor: $inputCursor) {
      items {
        spendingIndex
        ...TransactionInputFragment
      }
      hasMore
    }
    inputCount
  }
`)

function TransactionInputsTableBody(props: {
  highlightedInput?: number
  address?: string
  coin: string
  inputs: FragmentType<typeof TransactionInputsFragment>
}) {
  const { coin, highlightedInput, address } = props
  const data = getFragmentData(TransactionInputsFragment, props.inputs)
  return (
    <TableBody>
      {data.inputs.items.map((input) => (
        <TransactionInputRow
          input={input}
          coin={coin}
          address={address}
          key={input.spendingIndex}
          highlightedInput={highlightedInput}
        />
      ))}
    </TableBody>
  )
}

function FetchMoreButton(props: { inputs: FragmentType<typeof TransactionInputsFragment> }) {
  const { txid, coin } = useParams()
  if (!txid) throw new Error('Param txid is required')
  if (!coin) throw new Error('Param coin is required')
  const data = getFragmentData(TransactionInputsFragment, props.inputs)
  const [query, { loading }] = useLazyQuery(LoadMoreQuery, {
    fetchPolicy: 'network-only'
  })

  return (
    <LoadingButton
      loading={loading}
      variant="outlined"
      size="small"
      onClick={() => {
        query({
          variables: {
            cursor: {
              spendingIndex: data.inputs.items[data.inputs.items.length - 1].spendingIndex
            },
            coin,
            txid
          }
        })
      }}
    >
      Load more
    </LoadingButton>
  )
}

export function TransactionInputs(props: {
  highlightedInput?: number
  address?: string
  coin: string
  inputs: FragmentType<typeof TransactionInputsFragment>
}) {
  const { txid } = useParams()
  if (!txid) throw new Error('Param txid is required')
  const { coin, highlightedInput, address } = props
  const data = getFragmentData(TransactionInputsFragment, props.inputs)
  return (
    <Card sx={{ flex: '1 1 100%' }}>
      <CardHeader
        title={`Inputs (${data.inputCount})`}
        titleTypographyProps={{ variant: 'h6' }}
      ></CardHeader>
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: 'none' }} aria-label="Spent output"></TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>

            <TransactionInputsTableBody
              coin={coin}
              address={address}
              highlightedInput={highlightedInput}
              inputs={props.inputs}
            />
          </Table>
        </TableContainer>

        {data.inputs.hasMore && <FetchMoreButton inputs={props.inputs} />}
      </CardContent>
    </Card>
  )
}
