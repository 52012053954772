import { forwardRef, ReactElement } from 'react'

export function fixedForwardRef<
  T,
  P = {
    //
  }
>(
  render: (props: P, ref: React.Ref<T>) => ReactElement | null
): (props: P & React.RefAttributes<T>) => ReactElement | null {
  return forwardRef(render) as never
}
