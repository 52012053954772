import { Snackbar, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { CellElement } from '../common/table/CellElement'
import { BodyRowElement, HeaderRowElement } from '../common/table/RowElement'
import { RowGroupElement } from '../common/table/RowGroupElement'
import { TableElement } from '../common/table/TableElement'
import { FragmentType, getFragmentData, graphql } from '../gql'
import RouterLink, { RouterLinkProps } from '../RouterLink'
import { useBlocksPoller } from './blocksLoader'

export const blocksTableFragment = graphql(`
  fragment BlocksTableFragment on PaginatedIBlockHashResponse {
    items {
      height
      hash
      block {
        height
        hash
        txCount
        size
        time
      }
    }
  }
`)

const HeightCell = styled(CellElement)(() => ({
  flex: '1 0 70px'
}))

const TimeCell = styled(CellElement)(() => ({
  flex: '1 2 155px'
}))

const HashCell = styled(CellElement)(() => ({
  flex: '1 3 550px'
}))

const HashLinkCell = styled(HashCell)<{ component: typeof RouterLink } & RouterLinkProps>(() => ({
  overflowWrap: 'anywhere'
}))

const TxCountCell = styled(CellElement)(() => ({
  flex: '1 0 75px'
}))

export function BlocksTable({
  query,
  bip44_symbol,
  limit
}: {
  query: FragmentType<typeof blocksTableFragment>
  bip44_symbol: string
  limit?: number
}) {
  const { items } = getFragmentData(blocksTableFragment, query)
  const height = items[0].block.height
  useBlocksPoller(height)
  const [lastNotificationHeight, setLastNotificationHeight] = useState(height)
  const handleSnackbarClose = useCallback(() => {
    setLastNotificationHeight(height)
  }, [height])
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={height > lastNotificationHeight}
        onClose={handleSnackbarClose}
        message={`New block ${height}`}
        autoHideDuration={5000}
      />
      <TableElement aria-label="Blocks">
        <RowGroupElement>
          <HeaderRowElement>
            <HeightCell>Height</HeightCell>
            <TimeCell>Time</TimeCell>
            <HashCell>Hash</HashCell>
            <TxCountCell>Tx count</TxCountCell>
          </HeaderRowElement>
        </RowGroupElement>
        <RowGroupElement>
          {items.slice(0, limit).map((block) => (
            <BodyRowElement key={block.block.height}>
              <HeightCell>{block.block.height}</HeightCell>
              <TimeCell>{dayjs(block.block.time).format('L LT')}</TimeCell>
              <HashLinkCell
                component={RouterLink}
                to={`/${bip44_symbol.toLowerCase()}/blocks/${block.block.hash}`}
              >
                {block.block.hash}
              </HashLinkCell>
              <TxCountCell>{block.block.txCount}</TxCountCell>
            </BodyRowElement>
          ))}
        </RowGroupElement>
      </TableElement>
    </>
  )
}
