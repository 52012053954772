import { Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BalanceChange } from '../../BalanceChange'
import { addressTransactionFragment } from '../../common/addressTransactionFragment'
import { FragmentType, getFragmentData } from '../../gql'
import RouterLink from '../../RouterLink'

export function TimelineTransaction(props: {
  query: FragmentType<typeof addressTransactionFragment>
}) {
  const { coin, address } = useParams()
  if (!coin) throw new Error('Param coin is required')
  if (!address) throw new Error('Param address is required')
  const row = getFragmentData(addressTransactionFragment, props.query)
  return (
    <Typography sx={{ whiteSpace: { xs: 'normal', sm: 'nowrap' } }}>
      <BalanceChange balanceChange={row.balanceChange} component="span" />
      {' ' + coin.toUpperCase() + ' '}
      <RouterLink
        sx={{
          display: 'inline',
          whiteSpace: 'normal',
          overflowWrap: 'anywhere',
          flex: '0 1 290px'
        }}
        to={{
          pathname: `/${coin}/transaction/${row.confirmedTransaction.txid}`,
          search: `address=${address}`
        }}
      >
        {row.confirmedTransaction.txid}
      </RouterLink>
    </Typography>
  )
}
