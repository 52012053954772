import { LoadingButton } from '@mui/lab'
import { Card, CardContent, CardHeader, LinearProgress, Stack, styled } from '@mui/material'
import { useParams } from 'react-router-dom'
import { CellElement } from '../common/table/CellElement'
import { RowGroupElement } from '../common/table/RowGroupElement'
import { TableElement } from '../common/table/TableElement'
import { BodyRowElement, HeaderRowElement } from '../common/table/RowElement'
import { FragmentType, graphql, getFragmentData } from '../gql'
import RouterLink, { RouterLinkProps } from '../RouterLink'

const blockTransactionsFragment = graphql(`
  fragment BlockTransactionsFragment on IBlock {
    txCount
    transactions(direction: ASC, cursor: $cursor) {
      items {
        height
        txN
        transaction {
          txid
          inputCount
          outputCount
          fee
        }
      }
      hasMore
    }
  }
`)

export function BlockTransactionsSkeleton() {
  return (
    <Card>
      <CardHeader title={`Transactions`}></CardHeader>
      <CardContent>
        <LinearProgress sx={{ width: '30%', maxWidth: 160, mx: 'auto', my: 5 }} />
      </CardContent>
    </Card>
  )
}

const TxidCell = styled(CellElement)(() => ({
  flex: '1 5 600px'
}))

const TxidLinkCell = styled(TxidCell)<{ component: typeof RouterLink } & RouterLinkProps>(() => ({
  overflowWrap: 'anywhere'
}))

const FeeCell = styled(CellElement)(() => ({
  flex: '1 1 130px'
}))

const InputsCell = styled(CellElement)(() => ({
  flex: '1 1 80px'
}))

const OutputsCell = styled(CellElement)(() => ({
  flex: '1 1 80px'
}))

export function BlockTransactions({
  block,
  handleFetchMore,
  loading
}: {
  block: FragmentType<typeof blockTransactionsFragment>
  handleFetchMore: (txN: number) => void
  loading: boolean
}) {
  const { coin } = useParams()
  const blockTransactions = getFragmentData(blockTransactionsFragment, block)
  const { transactions, txCount } = blockTransactions
  return (
    <Card>
      <CardHeader title={`Transactions (${txCount})`}></CardHeader>
      <CardContent component={Stack} gap={2} alignItems="center">
        <TableElement aria-label="Transactions" width={1}>
          <RowGroupElement>
            <HeaderRowElement>
              <TxidCell>Txid</TxidCell>
              <FeeCell>Fee</FeeCell>
              <InputsCell>Inputs</InputsCell>
              <OutputsCell>Outputs</OutputsCell>
            </HeaderRowElement>
          </RowGroupElement>
          <RowGroupElement>
            {transactions?.items.map((transaction) => (
              <BodyRowElement key={transaction.transaction.txid}>
                <TxidLinkCell
                  component={RouterLink}
                  to={`../../transaction/${transaction.transaction.txid}`}
                  relative="path"
                >
                  {transaction.transaction.txid}
                </TxidLinkCell>
                <FeeCell>{`${transaction.transaction.fee} ${coin?.toUpperCase()}`}</FeeCell>
                <InputsCell>{transaction.transaction.inputCount}</InputsCell>
                <OutputsCell>{transaction.transaction.outputCount}</OutputsCell>
              </BodyRowElement>
            ))}
          </RowGroupElement>
        </TableElement>
        {transactions?.hasMore && (
          <LoadingButton
            variant="outlined"
            loading={loading}
            onClick={() => {
              handleFetchMore(transactions.items[transactions.items.length - 1].txN)
            }}
          >
            Load more
          </LoadingButton>
        )}
      </CardContent>
    </Card>
  )
}
