import { styled } from '@mui/material'
import { CellElement } from '../common/table/CellElement'
import { BodyRowElement, HeaderRowElement } from '../common/table/RowElement'
import { RowGroupElement } from '../common/table/RowGroupElement'
import { TableElement } from '../common/table/TableElement'
import { FragmentType, getFragmentData, graphql } from '../gql'
import RouterLink, { RouterLinkProps } from '../RouterLink'

export const TopLosersTableFragment = graphql(`
  fragment TopLosersTableFragment on PaginatedIAddressBalanceChangeResponse {
    items {
      address {
        address
      }
      balanceChange
    }
    hasMore
  }
`)

const BalanceCell = styled(CellElement)(() => ({
  flex: '0 2 200px'
}))

const AddressCell = styled(CellElement)(() => ({
  flex: '1 5 500px'
}))

const AddressLinkCell = styled(AddressCell)<{ component: typeof RouterLink } & RouterLinkProps>(
  () => ({
    overflowWrap: 'anywhere'
  })
)

export function TopLosersTable({
  query,
  bip44_symbol,
  limit
}: {
  query: FragmentType<typeof TopLosersTableFragment>
  bip44_symbol: string
  limit?: number
}) {
  const { items } = getFragmentData(TopLosersTableFragment, query)
  return (
    <TableElement aria-label="Top losers">
      <RowGroupElement>
        <HeaderRowElement>
          <BalanceCell>Balance Change</BalanceCell>
          <AddressCell>Address</AddressCell>
        </HeaderRowElement>
      </RowGroupElement>
      <RowGroupElement>
        {items.slice(0, limit).map((item) => {
          return (
            <BodyRowElement key={item.address.address}>
              <BalanceCell>
                {item.balanceChange} {bip44_symbol}
              </BalanceCell>
              <AddressLinkCell
                component={RouterLink}
                to={`/${bip44_symbol.toLowerCase()}/address/${item.address.address}`}
                underline="none"
              >
                {item.address.address}
              </AddressLinkCell>
            </BodyRowElement>
          )
        })}
      </RowGroupElement>
    </TableElement>
  )
}
