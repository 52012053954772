import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  Link as MuiLink
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { FragmentType, graphql, getFragmentData } from '../gql'
import RouterLink from '../RouterLink'
import { coinIcons } from '../util/CoinUtil'

const CoinItemFragment = graphql(`
  fragment CoinItemFragment on ICoin {
    blocks(limit: 1, direction: DESC) {
      items {
        block {
          height
          hash
          time
        }
        hash
        height
      }
    }
    mempool {
      totalFees
      txCount
    }
    name
    bip44_symbol
  }
`)

export const CoinItem = (props: { coin: FragmentType<typeof CoinItemFragment> }) => {
  const coin = getFragmentData(CoinItemFragment, props.coin)
  const lastBlock = coin.blocks.items[0].block
  const [currentTime, setCurrentTime] = useState(new Date().getTime() / 1000)
  const icon = coinIcons[coin.bip44_symbol.toUpperCase()]

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date().getTime() / 1000), 5000)
    return () => clearInterval(timer)
  }, [])

  const timeAgo = useMemo(() => {
    const secondsDiff = Math.floor(currentTime - lastBlock.time / 1000)
    if (secondsDiff < 60) return `${secondsDiff} seconds ago`
    const minutesDiff = Math.floor(secondsDiff / 60)
    if (minutesDiff < 60) return `${minutesDiff} minutes ago`
    const hoursDiff = Math.floor(minutesDiff / 60)
    return `${hoursDiff} hours ago`
  }, [currentTime, lastBlock.time])

  return (
    <Card>
      <CardActionArea component={RouterLink} to={`/${coin.bip44_symbol.toLocaleLowerCase()}`}>
        <CardHeader
          avatar={
            <Avatar>
              <SvgIcon component={icon} inheritViewBox sx={{ height: 1, width: 1 }} />
            </Avatar>
          }
          title={coin.name}
          subheader={coin.bip44_symbol}
        ></CardHeader>
        <CardContent>
          <Typography color={(theme) => theme.palette.text.secondary}>Latest block</Typography>
          {lastBlock.height} • {timeAgo}
          <Divider>Mempool</Divider>
          <Stack direction={'row'} justifyContent="space-between" flexWrap="wrap" gap={1}>
            <Box>
              <Typography color={(theme) => theme.palette.text.secondary}>Transactions</Typography>
              {coin.mempool.txCount}
            </Box>
            <Box>
              <Typography color={(theme) => theme.palette.text.secondary}>Fees</Typography>
              {coin.mempool.totalFees} {coin.bip44_symbol.toLocaleUpperCase()}
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export function SkeletonItem() {
  return (
    <Card>
      <CardActionArea component={MuiLink}>
        <CardHeader
          avatar={
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          }
          title={<Skeleton width={70} />}
          subheader={<Skeleton width={60} />}
        ></CardHeader>
        <CardContent>
          <Typography color={(theme) => theme.palette.text.secondary}>Latest block</Typography>
          <Typography>
            <Skeleton width={64} sx={{ display: 'inline-block' }} />
            {' • '}
            <Skeleton width={100} sx={{ display: 'inline-block' }} />
          </Typography>
          <Divider>Mempool</Divider>
          <Stack direction={'row'} justifyContent="space-between" flexWrap="wrap" gap={1}>
            <Box>
              <Typography color={(theme) => theme.palette.text.secondary}>Transactions</Typography>
              <Skeleton />
            </Box>
            <Box>
              <Typography color={(theme) => theme.palette.text.secondary}>Fees</Typography>
              <Skeleton width={100} />
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
