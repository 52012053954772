import { Grid, Skeleton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { FragmentType, graphql, getFragmentData } from '../gql'
import { useFormattedSize } from '../util/useFormattedSize'

const blockCardContentFragment = graphql(`
  fragment BlockCardContentFragment on IBlock {
    height
    size
    time
    difficulty
  }
`)

export function BlockCardContentSkeleton() {
  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4}>
        <Typography>Time</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <Skeleton width={90} />
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Size</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <Skeleton width={50} />
        </Typography>
      </Grid>
    </Grid>
  )
}

export function BlockCardContent({
  block
}: {
  block: FragmentType<typeof blockCardContentFragment>
}) {
  const blockContent = getFragmentData(blockCardContentFragment, block)
  const { size, time } = blockContent
  const formattedTime = useMemo(() => dayjs(time).format('l LT'), [time])
  const formattedSize = useFormattedSize(size)
  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4}>
        <Typography>Time</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{formattedTime}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Size</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{formattedSize}</Typography>
      </Grid>
    </Grid>
  )
}
