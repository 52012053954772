import { useReadQuery } from '@apollo/client'
import { useRouteLoaderData } from 'react-router-typesafe'
import { TopGainersTable } from '../../top-gainers/TopGainersTable'
import { coinPageLoader } from '../CoinPage'
import { TopGainersCardLayout } from './TopGainersCardLayout'

export function TopGainersCard() {
  const queryRef = useRouteLoaderData<typeof coinPageLoader>('coin_page')
  const { data } = useReadQuery(queryRef)
  if (!data.coinBySymbol) return null
  return (
    <TopGainersCardLayout
      content={
        <TopGainersTable
          query={data.coinBySymbol.date.topGainers}
          bip44_symbol={data.coinBySymbol.bip44_symbol}
          limit={5}
        />
      }
    />
  )
}
