import { Brightness4, Brightness7 } from '@mui/icons-material'
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  IconButton,
  Stack,
  ThemeProvider,
  Toolbar,
  useMediaQuery
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AppBreadcrumbs } from './AppBreadcrumbs'
import Search from './Search'

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const [dark, setDark] = useState(prefersDarkMode)

  const theme = useMemo(() => {
    return createTheme({
      shape: {
        borderRadius: 8
      },
      palette: {
        background: {
          default: dark ? '#000000' : '#f4f2ee'
        },
        mode: dark ? 'dark' : 'light'
      }
    })
  }, [dark])

  const toggleColorMode = useCallback(() => {
    setDark(!dark)
  }, [dark])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toolbar disableGutters component={'header'}>
        <Box sx={{ flexGrow: 1, flexShrink: 1, flexBasis: { md: 40 }, ml: { md: 3 } }} />
        <Container sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto' }}>
          <Search />
        </Container>
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 40,
            mr: { xs: 1, sm: 2, md: 3 },
            justifyContent: 'flex-end',
            display: 'flex'
          }}
        >
          <IconButton
            onClick={toggleColorMode}
            color="inherit"
            aria-label={`Change to ${theme.palette.mode === 'dark' ? 'light' : 'dark'} color mode`}
          >
            {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Box>
      </Toolbar>
      <Box>
        <Container>
          <Stack spacing={1} sx={{ pb: 2 }}>
            <AppBreadcrumbs />
            <main id="main-content">
              <Outlet />
            </main>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default App
