import { useLazyQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { FragmentType, graphql, getFragmentData } from '../gql'
import { TransactionOutput } from './TransactionOutput'

const LoadMoreQuery = graphql(`
  query LoadMoreTransactionOutputs(
    $coin: String!
    $txid: String!
    $cursor: TransactionOutputCursor
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      transaction(txid: $txid) {
        txid
        outputs(direction: ASC, limit: 100, cursor: $cursor) {
          items {
            n
            ...OutputFragment
          }
          hasMore
        }
      }
    }
  }
`)

const TransactionOutputsFragment = graphql(`
  fragment TransactionOutputsFragment on ITransaction {
    outputs(direction: ASC, limit: 100, cursor: $outputCursor) {
      items {
        n
        ...OutputFragment
      }
      hasMore
    }
    outputCount
  }
`)

export function TransactionOutputs(props: {
  highlightedOutput?: number
  address?: string
  outputs: FragmentType<typeof TransactionOutputsFragment>
}) {
  const { highlightedOutput, address } = props
  const { coin, txid } = useParams()
  if (!txid) throw new Error('Param txid is required')
  if (!coin) throw new Error('Param coin is required')
  const data = getFragmentData(TransactionOutputsFragment, props.outputs)
  const [query, { loading }] = useLazyQuery(LoadMoreQuery, {
    fetchPolicy: 'network-only'
  })
  return (
    <Card sx={{ flex: '1 1 100%' }}>
      <CardHeader
        title={`Outputs (${data.outputCount})`}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>Value</TableCell>
                <TableCell sx={{ border: 'none' }} aria-label="Spending input"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.outputs.items.map((output) => (
                <TransactionOutput
                  key={output.n}
                  query={output}
                  highlightedAddress={address ?? undefined}
                  highlightedOutput={highlightedOutput}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {data.outputs.hasMore && (
          <LoadingButton
            loading={loading}
            variant="outlined"
            size="small"
            onClick={() => {
              query({
                variables: {
                  coin,
                  txid,
                  cursor: {
                    n: data.outputs.items[data.outputs.items.length - 1].n
                  }
                }
              })
            }}
          >
            Load more
          </LoadingButton>
        )}
      </CardContent>
    </Card>
  )
}
