import { Launch } from '@mui/icons-material'
import { Card, CardContent, CardHeader, IconButton } from '@mui/material'
import dayjs from 'dayjs'
import RouterLink from '../../RouterLink'

export function TopLosersCardLayout(props: { content: React.ReactNode }) {
  return (
    <Card sx={{ height: 1 }}>
      <CardHeader
        title="Top losers"
        subheader={dayjs().subtract(1, 'day').format('L')}
        action={
          <IconButton component={RouterLink} to={`top-losers`}>
            <Launch />
          </IconButton>
        }
      />
      <CardContent>{props.content}</CardContent>
    </Card>
  )
}
