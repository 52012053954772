import { Box, styled } from '@mui/material'

export const CellElement = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    flexBasis: '100%',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
})) as typeof Box
