import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material'
import { Link as ReactRouterLink, LinkProps as ReactRouterLinkProps } from 'react-router-dom'
import { forwardRef } from 'react'

export type RouterLinkProps = ReactRouterLinkProps & Omit<MuiLinkProps, 'href'>

const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
  return <MuiLink {...props} component={ReactRouterLink} ref={ref} />
})

export default RouterLink
