import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { Avatar, Card, CardContent, CardHeader, IconButton, Stack } from '@mui/material'
import { Suspense, useTransition } from 'react'
import { useLoaderData } from 'react-router-typesafe'
import { UnconfirmedTransactionsTable } from './UnconfirmedTransactionsTable'
import { mempoolLoader } from './mempoolLoader'
import { Refresh, SwapHoriz } from '@mui/icons-material'
import { UnconfirmedTransactionsLoadMoreButton } from './UnconfirmedTransactionsLoadMoreButton'
import { MempoolCardContent } from './MempoolCardContent'
import { FragmentType, getFragmentData, graphql } from '../gql'

export const MempoolCardHeaderFragment = graphql(`
  fragment MempoolCardHeaderFragment on IMempool {
    txCount
  }
`)

function LoadedMempoolPageCardHeader({
  query
}: {
  query: FragmentType<typeof MempoolCardHeaderFragment>
}) {
  const { txCount } = getFragmentData(MempoolCardHeaderFragment, query)
  return <CardHeader title={`Unconfirmed transactions (${txCount})`} />
}

function LoadedMempoolPage() {
  const queryRef = useLoaderData<typeof mempoolLoader>()
  const [loading, startTransition] = useTransition()
  const { fetchMore, refetch } = useQueryRefHandlers(queryRef)
  const handleRefetch = () => {
    startTransition(() => {
      refetch()
    })
  }
  const { data } = useReadQuery(queryRef)
  const coin = data.coinBySymbol
  if (!coin) return null
  const transactions = coin.mempool.transactions
  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader
          title="Mempool"
          avatar={
            <Avatar>
              <SwapHoriz />
            </Avatar>
          }
          action={
            <IconButton
              disabled={loading}
              onClick={(e) => {
                handleRefetch()
              }}
            >
              <Refresh />
            </IconButton>
          }
        />
        <CardContent>
          <MempoolCardContent bip44_symbol={coin.bip44_symbol} query={coin.mempool} />
        </CardContent>
      </Card>
      <Card>
        <LoadedMempoolPageCardHeader query={coin.mempool} />
        <CardContent>
          <UnconfirmedTransactionsTable query={transactions} bip44_symbol={coin.bip44_symbol} />
          <UnconfirmedTransactionsLoadMoreButton query={transactions} fetchMore={fetchMore} />
        </CardContent>
      </Card>
    </Stack>
  )
}

function MempoolSkeleton() {
  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title="Mempool" />
        <CardContent></CardContent>
      </Card>
    </Stack>
  )
}

export function MempoolPage() {
  return (
    <Suspense fallback={<MempoolSkeleton />}>
      <LoadedMempoolPage />
    </Suspense>
  )
}
