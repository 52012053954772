import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import App from './App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ErrorPage from './ErrorPage'
import CoinPage, { coinPageLoader } from './coin/CoinPage'
import BlocksPage from './blocks/BlocksPage'
import BlockPage from './block/BlockPage'
import CoinsPage from './coins/CoinsPage'
import TransactionPage from './transaction/TransactionPage'
import AddressPage, { AddressCrumb } from './address/AddressPage'
import { AddressCandleStick } from './address/candlesticks/AddressCandlestick'
import { client } from './ApolloClient'
import AddressTimelineLazy from './address/timeline/AddressTimeline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TransactionCrumb } from './transaction/TransactionCrumb'
import { transactionLoader } from './transaction/transactionLoader'
import { BlockCrumb } from './block/BlockCrumb'
import { blockLoader } from './block/blockLoader'
import { coinLoader } from './coin/coinLoader'
import { CoinCrumb } from './coin/CoinCrumb'
import { coinsLoader } from './coins/coinsLoader'
import { timelineLoader } from './address/timeline/timelineLoader'
import { addressCandlesticksLoader } from './address/candlesticks/addressCandlesticksLoader'
import { ErrorBoundary } from 'react-error-boundary'
import { blocksLoader } from './blocks/blocksLoader'
import { CoinContainer } from './coin/CoinContainer'
import RichListPage from './rich-list/RichListPage'
import { richListLoader } from './rich-list/richListLoader'
import { topGainersLoader } from './top-gainers/topGainersLoader'
import TopGainersPage from './top-gainers/TopGainersPage'
import { topLosersLoader } from './top-losers/topLosersLoader'
import TopLosersPage from './top-losers/TopLosersPage'
import { MempoolPage } from './mempool/MempoolPage'
import { mempoolLoader } from './mempool/mempoolLoader'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: 'Home'
    },
    children: [
      {
        path: '/',
        loader: coinsLoader,
        element: <CoinsPage />
      },
      {
        id: 'coin',
        path: ':coin',
        loader: coinLoader,
        handle: {
          crumb: <CoinCrumb />
        },
        element: <CoinContainer />,
        errorElement: <ErrorPage />,
        children: [
          {
            id: 'coin_page',
            index: true,
            loader: coinPageLoader,
            element: <CoinPage />
          },
          { id: 'mempool', path: 'mempool', element: <MempoolPage />, loader: mempoolLoader },
          {
            id: 'blocks',
            path: 'blocks',
            loader: blocksLoader,
            element: <BlocksPage />,
            handle: {
              crumb: 'Blocks'
            }
          },
          {
            id: 'richlist',
            path: 'richlist/:date?',
            handle: {
              crumb: 'Rich List'
            },
            element: <RichListPage />,
            loader: richListLoader
          },
          {
            id: 'top_gainers',
            path: 'top-gainers/:date?',
            element: <TopGainersPage />,
            handle: {
              crumb: 'Top Gainers'
            },
            loader: topGainersLoader
          },
          {
            id: 'top-losers',
            path: 'top-losers/:date?',
            element: <TopLosersPage />,
            handle: {
              crumb: 'Top Losers'
            },
            loader: topLosersLoader
          },
          {
            path: 'blocks',
            handle: {
              crumb: 'Blocks'
            },
            children: [
              {
                id: 'block',
                path: ':blockId',
                element: <BlockPage />,
                loader: blockLoader,
                handle: {
                  crumb: <BlockCrumb />
                }
              }
            ]
          },
          {
            path: 'transaction',
            handle: { crumb: 'Transaction', disableBreadcrumbLink: true },
            children: [
              {
                path: ':txid',
                loader: transactionLoader,
                element: <TransactionPage />,
                errorElement: <ErrorPage />,
                handle: {
                  crumb: <TransactionCrumb />
                }
              }
            ]
          },

          {
            path: 'graph',
            //element
            lazy: async () => {
              const res = await import('./graph/Graph')
              return {
                Component: res.default
              }
            }
          },
          {
            path: 'address',
            handle: {
              crumb: 'Address',
              disableBreadcrumbLink: true
            },
            children: [
              {
                path: ':address',
                element: <AddressPage />,
                handle: {
                  crumb: <AddressCrumb />
                },
                children: [
                  {
                    path: 'chart',
                    element: <AddressCandleStick />,
                    loader: addressCandlesticksLoader,
                    handle: { crumb: 'Chart' }
                  },
                  {
                    id: 'address-timeline',
                    loader: timelineLoader,
                    children: [
                      {
                        path: '',
                        element: <AddressTimelineLazy />
                      },
                      {
                        path: 'timeline',
                        element: <AddressTimelineLazy />,
                        handle: { crumb: 'Timeline' }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={({ error }) => <div>Uncaught error: {JSON.stringify(error)}</div>}
    >
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
