import { Grid, Typography } from '@mui/material'
import { FragmentType, getFragmentData, graphql } from '../gql'

export const MempoolCardContentFragment = graphql(`
  fragment MempoolCardContentFragment on IMempool {
    txCount
    totalFees
  }
`)

interface Props {
  bip44_symbol: string
  query: FragmentType<typeof MempoolCardContentFragment>
  feesTitle?: string
  transactionsTitle?: string
}

export function MempoolCardContent({
  bip44_symbol,
  query,
  transactionsTitle = 'Transactions',
  feesTitle = 'Fees'
}: Props) {
  const { totalFees, txCount } = getFragmentData(MempoolCardContentFragment, query)
  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4}>
        <Typography>{transactionsTitle}</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{txCount}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{feesTitle}</Typography>
        <Typography
          color={(theme) => theme.palette.text.secondary}
        >{`${totalFees} ${bip44_symbol.toUpperCase()}`}</Typography>
      </Grid>
    </Grid>
  )
}
