import { useParams, Link, Outlet, useMatch } from 'react-router-dom'
import { Avatar, Box, Card, CardContent, CardHeader, Tab, Tabs } from '@mui/material'
import { AccountBalanceWallet, CandlestickChart, ViewTimeline } from '@mui/icons-material'
import { useEffect } from 'react'
import { CoinSymbolToName } from '../util/CoinUtil'

export function AddressCrumb() {
  const { address } = useParams()
  if (!address) throw new Error('Param address is required')
  return <Box sx={{ overflowWrap: 'anywhere' }}>{address}</Box>
}

function AddressPage() {
  const { coin, address } = useParams()
  const match = useMatch('/:coin/address/:address/:tab')
  const currentTab = match?.params.tab ?? 'timeline'
  if (!coin) throw new Error('Param coin is required')
  if (!address) throw new Error('Param address is required')
  useEffect(() => {
    const oldTitle = document.title
    document.title = `${CoinSymbolToName[coin.toUpperCase()]} Address ${address}`
    return () => {
      document.title = oldTitle
    }
  }, [coin, address])
  return (
    <>
      <Card sx={{ overflow: 'visible' }}>
        <CardHeader
          avatar={
            <Avatar aria-label="Address">
              <AccountBalanceWallet />
            </Avatar>
          }
          title={`Address`}
          subheader={address}
          subheaderTypographyProps={{ sx: { overflowWrap: 'anywhere' } }}
        ></CardHeader>
        <CardContent>
          <Tabs centered value={currentTab}>
            <Tab
              value="timeline"
              label="Timeline"
              icon={<ViewTimeline />}
              component={Link}
              to="timeline"
            />
            <Tab
              value="chart"
              label="Balance chart"
              icon={<CandlestickChart />}
              component={Link}
              to="chart"
            />
          </Tabs>
          <Outlet />
        </CardContent>
      </Card>
    </>
  )
}

export default AddressPage
