
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "IAddress": [
      "BitcoinAddress",
      "BitcoinCashAddress",
      "DashAddress",
      "DogecoinAddress",
      "LitecoinAddress"
    ],
    "IAddressBalanceChange": [
      "BitcoinAddressBalanceChange",
      "BitcoinCashAddressBalanceChange",
      "DashAddressBalanceChange",
      "DogecoinAddressBalanceChange",
      "LitecoinAddressBalanceChange"
    ],
    "IAddressBlock": [
      "BitcoinAddressBlock",
      "BitcoinCashAddressBlock",
      "DashAddressBlock",
      "DogecoinAddressBlock",
      "LitecoinAddressBlock"
    ],
    "IAddressTransaction": [
      "BitcoinAddressTransaction",
      "BitcoinCashAddressTransaction",
      "DashAddressTransaction",
      "DogecoinAddressTransaction",
      "LitecoinAddressTransaction"
    ],
    "IAddressUtxo": [
      "BitcoinAddressUtxo",
      "BitcoinCashAddressUtxo",
      "DashAddressUtxo",
      "DogecoinAddressUtxo",
      "LitecoinAddressUtxo"
    ],
    "IBlock": [
      "BitcoinBlock",
      "BitcoinCashBlock",
      "DashBlock",
      "DogecoinBlock",
      "LitecoinBlock"
    ],
    "IBlockHash": [
      "BitcoinBlockHash",
      "BitcoinCashBlockHash",
      "DashBlockHash",
      "DogecoinBlockHash",
      "LitecoinBlockHash"
    ],
    "ICoin": [
      "Bitcoin",
      "BitcoinCash",
      "Dash",
      "Dogecoin",
      "Litecoin"
    ],
    "IConfirmedTransaction": [
      "BitcoinCashConfirmedTransaction",
      "BitcoinConfirmedTransaction",
      "DashConfirmedTransaction",
      "DogecoinConfirmedTransaction",
      "LitecoinConfirmedTransaction"
    ],
    "IDate": [
      "BitcoinCashDate",
      "BitcoinDate",
      "DashDate",
      "DogecoinDate",
      "LitecoinDate"
    ],
    "IMempool": [
      "BitcoinCashMempool",
      "BitcoinMempool",
      "DashMempool",
      "DogecoinMempool",
      "LitecoinMempool"
    ],
    "IRichList": [
      "BitcoinCashRichList",
      "BitcoinRichList",
      "DashRichList",
      "DogecoinRichList",
      "LitecoinRichList"
    ],
    "IScriptPubKey": [
      "BitcoinCashScriptPubKey",
      "BitcoinScriptPubKey",
      "DashScriptPubKey",
      "DogecoinScriptPubKey",
      "LitecoinScriptPubKey"
    ],
    "ITransaction": [
      "BitcoinCashTransaction",
      "BitcoinTransaction",
      "DashTransaction",
      "DogecoinTransaction",
      "LitecoinTransaction"
    ],
    "ITransactionInput": [
      "BitcoinCashTransactionInput",
      "BitcoinTransactionInput",
      "DashTransactionInput",
      "DogecoinTransactionInput",
      "LitecoinTransactionInput"
    ],
    "ITransactionOutput": [
      "BitcoinCashTransactionOutput",
      "BitcoinTransactionOutput",
      "DashTransactionOutput",
      "DogecoinTransactionOutput",
      "LitecoinTransactionOutput"
    ],
    "IUnconfirmedAddressTransaction": [
      "BitcoinCashUnconfirmedAddressTransaction",
      "BitcoinUnconfirmedAddressTransaction",
      "DashUnconfirmedAddressTransaction",
      "DogecoinUnconfirmedAddressTransaction",
      "LitecoinUnconfirmedAddressTransaction"
    ],
    "IUnconfirmedTransaction": [
      "BitcoinCashUnconfirmedTransaction",
      "BitcoinUnconfirmedTransaction",
      "DashUnconfirmedTransaction",
      "DogecoinUnconfirmedTransaction",
      "LitecoinUnconfirmedTransaction"
    ],
    "PaginatedIAddressBalanceChangeResponse": [
      "BitcoinCashPaginatedAddressBalanceChangeResponse",
      "BitcoinPaginatedAddressBalanceChangeResponse",
      "DashPaginatedAddressBalanceChangeResponse",
      "DogecoinPaginatedAddressBalanceChangeResponse",
      "LitecoinPaginatedAddressBalanceChangeResponse"
    ],
    "PaginatedIAddressBalanceResponse": [
      "BitcoinCashPaginatedAddressBalanceResponse",
      "BitcoinPaginatedAddressBalanceResponse",
      "DashPaginatedAddressBalanceResponse",
      "DogecoinPaginatedAddressBalanceResponse",
      "LitecoinPaginatedAddressBalanceResponse"
    ],
    "PaginatedIAddressTransactionResponse": [
      "BitcoinCashPaginatedAddressTransactionResponse",
      "BitcoinPaginatedAddressTransactionResponse",
      "DashPaginatedAddressTransactionResponse",
      "DogecoinPaginatedAddressTransactionResponse",
      "LitecoinPaginatedAddressTransactionResponse"
    ],
    "PaginatedIAddressUtxoResponse": [
      "BitcoinCashPaginatedAddressUtxoResponse",
      "BitcoinPaginatedAddressUtxoResponse",
      "DashPaginatedAddressUtxoResponse",
      "DogecoinPaginatedAddressUtxoResponse",
      "LitecoinPaginatedAddressUtxoResponse"
    ],
    "PaginatedIBlockHashResponse": [
      "BitcoinCashPaginatedBlockHashResponse",
      "BitcoinPaginatedBlockHashResponse",
      "DashPaginatedBlockHashResponse",
      "DogecoinPaginatedBlockHashResponse",
      "LitecoinPaginatedBlockHashResponse"
    ],
    "PaginatedIConfirmedTransactionResponse": [
      "BitcoinCashPaginatedConfirmedTransactionResponse",
      "BitcoinPaginatedConfirmedTransactionResponse",
      "DashPaginatedConfirmedTransactionResponse",
      "DogecoinPaginatedConfirmedTransactionResponse",
      "LitecoinPaginatedConfirmedTransactionResponse"
    ],
    "PaginatedIRichlistResponse": [
      "BitcoinCashPaginatedRichlistResponse",
      "BitcoinPaginatedRichlistResponse",
      "DashPaginatedRichlistResponse",
      "DogecoinPaginatedRichlistResponse",
      "LitecoinPaginatedRichlistResponse"
    ],
    "PaginatedITransactionInputResponse": [
      "BitcoinCashPaginatedTransactionInputResponse",
      "BitcoinPaginatedTransactionInputResponse",
      "DashPaginatedTransactionInputResponse",
      "DogecoinPaginatedTransactionInputResponse",
      "LitecoinPaginatedTransactionInputResponse"
    ],
    "PaginatedITransactionOutputResponse": [
      "BitcoinCashPaginatedTransactionOutputResponse",
      "BitcoinPaginatedTransactionOutputResponse",
      "DashPaginatedTransactionOutputResponse",
      "DogecoinPaginatedTransactionOutputResponse",
      "LitecoinPaginatedTransactionOutputResponse"
    ],
    "PaginatedIUnconfirmedAddressTransactionResponse": [
      "BitcoinCashPaginatedUnconfirmedAddressTransactionResponse",
      "BitcoinPaginatedUnconfirmedAddressTransactionResponse",
      "DashPaginatedUnconfirmedAddressTransactionResponse",
      "DogecoinPaginatedUnconfirmedAddressTransactionResponse",
      "LitecoinPaginatedUnconfirmedAddressTransactionResponse"
    ],
    "PaginatedIUnconfirmedTransactionResponse": [
      "BitcoinCashPaginatedUnconfirmedTransactionResponse",
      "BitcoinPaginatedUnconfirmedTransactionResponse",
      "DashPaginatedUnconfirmedTransactionResponse",
      "DogecoinPaginatedUnconfirmedTransactionResponse",
      "LitecoinPaginatedUnconfirmedTransactionResponse"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "IAddress": [
      "BitcoinAddress",
      "BitcoinCashAddress",
      "DashAddress",
      "DogecoinAddress",
      "LitecoinAddress"
    ],
    "IAddressBalanceChange": [
      "BitcoinAddressBalanceChange",
      "BitcoinCashAddressBalanceChange",
      "DashAddressBalanceChange",
      "DogecoinAddressBalanceChange",
      "LitecoinAddressBalanceChange"
    ],
    "IAddressBlock": [
      "BitcoinAddressBlock",
      "BitcoinCashAddressBlock",
      "DashAddressBlock",
      "DogecoinAddressBlock",
      "LitecoinAddressBlock"
    ],
    "IAddressTransaction": [
      "BitcoinAddressTransaction",
      "BitcoinCashAddressTransaction",
      "DashAddressTransaction",
      "DogecoinAddressTransaction",
      "LitecoinAddressTransaction"
    ],
    "IAddressUtxo": [
      "BitcoinAddressUtxo",
      "BitcoinCashAddressUtxo",
      "DashAddressUtxo",
      "DogecoinAddressUtxo",
      "LitecoinAddressUtxo"
    ],
    "IBlock": [
      "BitcoinBlock",
      "BitcoinCashBlock",
      "DashBlock",
      "DogecoinBlock",
      "LitecoinBlock"
    ],
    "IBlockHash": [
      "BitcoinBlockHash",
      "BitcoinCashBlockHash",
      "DashBlockHash",
      "DogecoinBlockHash",
      "LitecoinBlockHash"
    ],
    "ICoin": [
      "Bitcoin",
      "BitcoinCash",
      "Dash",
      "Dogecoin",
      "Litecoin"
    ],
    "IConfirmedTransaction": [
      "BitcoinCashConfirmedTransaction",
      "BitcoinConfirmedTransaction",
      "DashConfirmedTransaction",
      "DogecoinConfirmedTransaction",
      "LitecoinConfirmedTransaction"
    ],
    "IDate": [
      "BitcoinCashDate",
      "BitcoinDate",
      "DashDate",
      "DogecoinDate",
      "LitecoinDate"
    ],
    "IMempool": [
      "BitcoinCashMempool",
      "BitcoinMempool",
      "DashMempool",
      "DogecoinMempool",
      "LitecoinMempool"
    ],
    "IRichList": [
      "BitcoinCashRichList",
      "BitcoinRichList",
      "DashRichList",
      "DogecoinRichList",
      "LitecoinRichList"
    ],
    "IScriptPubKey": [
      "BitcoinCashScriptPubKey",
      "BitcoinScriptPubKey",
      "DashScriptPubKey",
      "DogecoinScriptPubKey",
      "LitecoinScriptPubKey"
    ],
    "ITransaction": [
      "BitcoinCashTransaction",
      "BitcoinTransaction",
      "DashTransaction",
      "DogecoinTransaction",
      "LitecoinTransaction"
    ],
    "ITransactionInput": [
      "BitcoinCashTransactionInput",
      "BitcoinTransactionInput",
      "DashTransactionInput",
      "DogecoinTransactionInput",
      "LitecoinTransactionInput"
    ],
    "ITransactionOutput": [
      "BitcoinCashTransactionOutput",
      "BitcoinTransactionOutput",
      "DashTransactionOutput",
      "DogecoinTransactionOutput",
      "LitecoinTransactionOutput"
    ],
    "IUnconfirmedAddressTransaction": [
      "BitcoinCashUnconfirmedAddressTransaction",
      "BitcoinUnconfirmedAddressTransaction",
      "DashUnconfirmedAddressTransaction",
      "DogecoinUnconfirmedAddressTransaction",
      "LitecoinUnconfirmedAddressTransaction"
    ],
    "IUnconfirmedTransaction": [
      "BitcoinCashUnconfirmedTransaction",
      "BitcoinUnconfirmedTransaction",
      "DashUnconfirmedTransaction",
      "DogecoinUnconfirmedTransaction",
      "LitecoinUnconfirmedTransaction"
    ],
    "PaginatedIAddressBalanceChangeResponse": [
      "BitcoinCashPaginatedAddressBalanceChangeResponse",
      "BitcoinPaginatedAddressBalanceChangeResponse",
      "DashPaginatedAddressBalanceChangeResponse",
      "DogecoinPaginatedAddressBalanceChangeResponse",
      "LitecoinPaginatedAddressBalanceChangeResponse"
    ],
    "PaginatedIAddressBalanceResponse": [
      "BitcoinCashPaginatedAddressBalanceResponse",
      "BitcoinPaginatedAddressBalanceResponse",
      "DashPaginatedAddressBalanceResponse",
      "DogecoinPaginatedAddressBalanceResponse",
      "LitecoinPaginatedAddressBalanceResponse"
    ],
    "PaginatedIAddressTransactionResponse": [
      "BitcoinCashPaginatedAddressTransactionResponse",
      "BitcoinPaginatedAddressTransactionResponse",
      "DashPaginatedAddressTransactionResponse",
      "DogecoinPaginatedAddressTransactionResponse",
      "LitecoinPaginatedAddressTransactionResponse"
    ],
    "PaginatedIAddressUtxoResponse": [
      "BitcoinCashPaginatedAddressUtxoResponse",
      "BitcoinPaginatedAddressUtxoResponse",
      "DashPaginatedAddressUtxoResponse",
      "DogecoinPaginatedAddressUtxoResponse",
      "LitecoinPaginatedAddressUtxoResponse"
    ],
    "PaginatedIBlockHashResponse": [
      "BitcoinCashPaginatedBlockHashResponse",
      "BitcoinPaginatedBlockHashResponse",
      "DashPaginatedBlockHashResponse",
      "DogecoinPaginatedBlockHashResponse",
      "LitecoinPaginatedBlockHashResponse"
    ],
    "PaginatedIConfirmedTransactionResponse": [
      "BitcoinCashPaginatedConfirmedTransactionResponse",
      "BitcoinPaginatedConfirmedTransactionResponse",
      "DashPaginatedConfirmedTransactionResponse",
      "DogecoinPaginatedConfirmedTransactionResponse",
      "LitecoinPaginatedConfirmedTransactionResponse"
    ],
    "PaginatedIRichlistResponse": [
      "BitcoinCashPaginatedRichlistResponse",
      "BitcoinPaginatedRichlistResponse",
      "DashPaginatedRichlistResponse",
      "DogecoinPaginatedRichlistResponse",
      "LitecoinPaginatedRichlistResponse"
    ],
    "PaginatedITransactionInputResponse": [
      "BitcoinCashPaginatedTransactionInputResponse",
      "BitcoinPaginatedTransactionInputResponse",
      "DashPaginatedTransactionInputResponse",
      "DogecoinPaginatedTransactionInputResponse",
      "LitecoinPaginatedTransactionInputResponse"
    ],
    "PaginatedITransactionOutputResponse": [
      "BitcoinCashPaginatedTransactionOutputResponse",
      "BitcoinPaginatedTransactionOutputResponse",
      "DashPaginatedTransactionOutputResponse",
      "DogecoinPaginatedTransactionOutputResponse",
      "LitecoinPaginatedTransactionOutputResponse"
    ],
    "PaginatedIUnconfirmedAddressTransactionResponse": [
      "BitcoinCashPaginatedUnconfirmedAddressTransactionResponse",
      "BitcoinPaginatedUnconfirmedAddressTransactionResponse",
      "DashPaginatedUnconfirmedAddressTransactionResponse",
      "DogecoinPaginatedUnconfirmedAddressTransactionResponse",
      "LitecoinPaginatedUnconfirmedAddressTransactionResponse"
    ],
    "PaginatedIUnconfirmedTransactionResponse": [
      "BitcoinCashPaginatedUnconfirmedTransactionResponse",
      "BitcoinPaginatedUnconfirmedTransactionResponse",
      "DashPaginatedUnconfirmedTransactionResponse",
      "DogecoinPaginatedUnconfirmedTransactionResponse",
      "LitecoinPaginatedUnconfirmedTransactionResponse"
    ]
  }
};
      export default result;
    