import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'

const mempoolQuery = graphql(`
  query mempool(
    $coin: String!
    $unconfirmedTransactionsCursor: UnconfirmedTransactionCursor
    $unconfirmedTransactionsLimit: Int
  ) {
    coinBySymbol(symbol: $coin) {
      mempool {
        ...MempoolCardContentFragment
        ...MempoolCardHeaderFragment
        transactions(
          direction: DESC
          cursor: $unconfirmedTransactionsCursor
          limit: $unconfirmedTransactionsLimit
        ) {
          ...UnconfirmedTransactionsTableFragment
        }
      }
      bip44_symbol
    }
  }
`)

export const mempoolLoader = (({ params: { coin } }) => {
  if (!coin) throw new Error('Param coin is required')
  return preloadQuery(mempoolQuery, {
    variables: { coin },
    fetchPolicy: 'network-only'
  })
}) satisfies LoaderFunction
