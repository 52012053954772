import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { useLoaderData } from 'react-router-typesafe'
import { richListLoader } from './richListLoader'
import { RichListLoadMoreButton } from './RichListLoadMoreButton'
import { RichListTable } from './RichListTable'

export function RichListPageContent() {
  const queryRef = useLoaderData<typeof richListLoader>()
  const { fetchMore } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery(queryRef)
  const coin = data.coinBySymbol
  if (!coin) return null
  return (
    <>
      <RichListTable query={coin.date.richList} bip44_symbol={coin.bip44_symbol} />
      <RichListLoadMoreButton query={coin.date.richList} fetchMore={fetchMore} />
    </>
  )
}
