import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { useLoaderData } from 'react-router-typesafe'
import { topLosersLoader } from './topLosersLoader'
import { TopLosersLoadMoreButton } from './TopLosersLoadMoreButton'
import { TopLosersTable } from './TopLosersTable'

export function TopLosersPageContent() {
  const queryRef = useLoaderData<typeof topLosersLoader>()
  const { fetchMore } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery(queryRef)
  const coin = data.coinBySymbol
  if (!coin) return null
  return (
    <>
      <TopLosersTable query={coin.date.topLosers} bip44_symbol={coin.bip44_symbol} />
      <TopLosersLoadMoreButton query={coin.date.topLosers} fetchMore={fetchMore} />
    </>
  )
}
