import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

export function CoinContainer() {
  const { coin } = useParams()
  if (!coin) throw new Error('Param coin is required')
  useEffect(() => {
    const favicon = document.getElementById('favicon')
    if (!favicon) return
    if ('href' in favicon && typeof favicon.href === 'string') {
      const oldHref = favicon.href
      favicon.href = `${process.env.PUBLIC_URL}/favicon_${coin.toLowerCase()}.ico`
      return () => {
        favicon.href = oldHref
      }
    }
  }, [coin])

  return <Outlet />
}
