import { graphql } from '../gql'

export const addressTransactionFragment = graphql(`
  fragment AddressTransactionFragment on IAddressTransaction {
    txN
    balanceChange
    confirmedTransaction {
      height
      txN
      txid
    }
  }
`)
