import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { graphql } from '../gql'

const mempoolPollQuery = graphql(`
  query mempoolPoller($coin: String!) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      mempool {
        ...MempoolCardContentFragment
        transactions(direction: DESC, limit: 5) {
          ...UnconfirmedTransactionsTableFragment
        }
      }
    }
  }
`)

export const useMempoolPoller = () => {
  const { coin } = useParams()
  if (!coin) throw new Error('Coin param is required')
  const args = useMemo(() => {
    return {
      pollInterval: 1000,
      fetchPolicy: 'network-only' as const,
      variables: { coin }
    }
  }, [coin])
  const [query] = useLazyQuery(mempoolPollQuery, args)
  useEffect(() => {
    const timer = setTimeout(() => {
      query()
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [query])
}
