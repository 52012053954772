import { LinearProgress } from '@mui/material'
import { UnconfirmedTransactionsCardLayout } from './UnconfirmedTransactionsCardLayout'

export function UnconfirmedTransactionsCardSkeleton() {
  return (
    <UnconfirmedTransactionsCardLayout
      content={<LinearProgress />}
    ></UnconfirmedTransactionsCardLayout>
  )
}
