import dayjs from 'dayjs'
import { BodyRowElement } from '../common/table/RowElement'
import { FragmentType, getFragmentData, graphql } from '../gql'
import RouterLink from '../RouterLink'
import { InputsCell, OutputsCell, TimeCell, TxidLinkCell } from './UnconfirmedTransactionsTable'

interface Props {
  item: FragmentType<typeof UnconfirmedTransactionFragment>
  bip44_symbol: string
}

export const UnconfirmedTransactionFragment = graphql(`
  fragment UnconfirmedTransactionFragment on IUnconfirmedTransaction {
    txid
    timestamp
    transaction {
      txid
      inputCount
      outputCount
    }
  }
`)

export function UnconfirmedTransactionsTableRow({ item, bip44_symbol }: Props) {
  const tx = getFragmentData(UnconfirmedTransactionFragment, item)
  return (
    <BodyRowElement>
      <TimeCell>{dayjs(tx.timestamp).format('L LTS')}</TimeCell>
      <TxidLinkCell
        component={RouterLink}
        to={`/${bip44_symbol.toLowerCase()}/transaction/${tx.txid}`}
      >
        {tx.txid}
      </TxidLinkCell>
      <InputsCell>{tx.transaction.inputCount}</InputsCell>
      <OutputsCell>{tx.transaction.outputCount}</OutputsCell>
    </BodyRowElement>
  )
}
