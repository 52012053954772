import { Grid, Skeleton, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { FragmentType, graphql, getFragmentData } from '../gql'
import RouterLink from '../RouterLink'
import { useFormattedSize } from '../util/useFormattedSize'

const TransactionDetailsFragment = graphql(`
  fragment TransactionDetails on ITransaction {
    blockHash {
      ...BlockLink
    }
    fee
    size
  }
`)

const BlockLinkFragment = graphql(`
  fragment BlockLink on IBlockHash {
    height
    hash
    block {
      hash
      time
    }
  }
`)

function TransactionDetailsInner(props: {
  blockLink: React.ReactNode
  fee: React.ReactNode
  size: React.ReactNode
}) {
  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4}>
        <Typography>Included in block</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{props.blockLink}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Fee</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{props.fee}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>Size</Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>{props.size}</Typography>
      </Grid>
    </Grid>
  )
}

export function TransactionDetailsSkeleton() {
  return (
    <TransactionDetailsInner
      blockLink={<Skeleton width={70} />}
      fee={<Skeleton width={90} />}
      size={<Skeleton width={60} />}
    />
  )
}

function BlockLink(props: { blockLink: FragmentType<typeof BlockLinkFragment> }): JSX.Element {
  const { coin } = useParams()
  if (!coin) throw new Error('Param coin is required')
  const blockHash = getFragmentData(BlockLinkFragment, props.blockLink)
  return (
    <>
      <RouterLink to={`/${coin.toLowerCase()}/blocks/${blockHash.hash}`}>
        {blockHash.height}
      </RouterLink>{' '}
      • {dayjs(blockHash.block.time).format('l LT')}
    </>
  )
}

export function TransactionDetails(props: {
  transaction: FragmentType<typeof TransactionDetailsFragment>
}) {
  const { coin } = useParams()
  const { blockHash, fee, size } = getFragmentData(TransactionDetailsFragment, props.transaction)
  if (!coin) throw new Error('Param coin is required')
  const sizeFormatted = useFormattedSize(size)
  const blockLink2 = blockHash ? (
    <BlockLink blockLink={blockHash} />
  ) : (
    <Typography
      component={'span'}
      color={(theme) => theme.palette.error.main}
      fontWeight={(theme) => theme.typography.fontWeightMedium}
    >
      Unconfirmed transaction!
    </Typography>
  )
  return (
    <TransactionDetailsInner
      blockLink={blockLink2}
      fee={`${fee} ${coin.toUpperCase()}`}
      size={sizeFormatted}
    />
  )
}
