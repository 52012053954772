import { AttachMoney } from '@mui/icons-material'
import { Avatar, Card, CardContent, CardHeader, LinearProgress } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Suspense, useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { CustomDatePickerField } from '../CustomDatePickerField'
import { CoinSymbolToName } from '../util/CoinUtil'
import { dayjsToQueryVariable, useDayjsDate } from '../util/DateUtils'
import { RichListPageContent } from './RichListPageContent'

function RichListPage() {
  const { date, coin } = useParams()
  const dayJsDate = useDayjsDate(date)
  const navigate = useNavigate()
  if (!coin) throw new Error('Param coin is required')
  useEffect(() => {
    const oldTitle = document.title
    document.title = `${CoinSymbolToName[coin.toUpperCase()]} Rich List`
    return () => {
      document.title = oldTitle
    }
  }, [coin])
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <AttachMoney />
          </Avatar>
        }
        title="Rich List"
        subheader={
          <DatePicker
            slots={{ field: CustomDatePickerField }}
            value={dayJsDate}
            minDate={dayjs('2009-01-09')} //First bitcoin block
            maxDate={dayjs().add(-1, 'day')}
            onChange={(newValue) => {
              if (newValue)
                navigate(
                  generatePath('/:coin/richlist/:date', {
                    coin,
                    date: dayjsToQueryVariable(newValue)
                  })
                )
            }}
          />
        }
      />
      <CardContent>
        <Suspense
          fallback={<LinearProgress sx={{ width: '30%', maxWidth: 160, mx: 'auto', my: 5 }} />}
        >
          <RichListPageContent />
        </Suspense>
      </CardContent>
    </Card>
  )
}

export default RichListPage
