import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { useLoaderData } from 'react-router-typesafe'
import { blocksLoader } from './blocksLoader'
import { BlocksLoadMoreButton } from './BlocksLoadMoreButton'
import { BlocksTable } from './BlocksTable'

export function BlockPageContent() {
  const queryRef = useLoaderData<typeof blocksLoader>()
  const { fetchMore } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery(queryRef)
  const coin = data.coinBySymbol
  if (!coin) return null
  return (
    <>
      <BlocksTable query={coin.blocks} bip44_symbol={coin.bip44_symbol} />
      <BlocksLoadMoreButton query={coin.blocks} fetchMore={fetchMore} />
    </>
  )
}
