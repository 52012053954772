import { Stack, StackProps, Typography, TypographyProps } from '@mui/material'
import { fixedForwardRef } from './fixedForwardRef'

//type RowElementInnerProps<C extends React.ElementType> = StackProps<C, { component?: C }>

/*function RowElementInner<C extends React.ElementType>(
  props: RowElementInnerProps<C>,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <Stack
      role="row"
      direction="row"
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      alignItems="center"
      ref={ref}
      {...props}
    />
  )
}
export const RowElement = fixedForwardRef(RowElementInner)*/

type Props = TypographyProps & StackProps

function HeaderRowElementInnner(props: Props, ref: React.Ref<HTMLDivElement>) {
  return (
    <Typography
      ref={ref}
      component={Stack}
      role="row"
      direction="row"
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      alignItems="center"
      variant="subtitle2"
      {...props}
    />
  )
}

export const HeaderRowElement = fixedForwardRef(HeaderRowElementInnner)

function BodyRowElementInnner(props: Props, ref: React.Ref<HTMLDivElement>) {
  return (
    <Typography
      ref={ref}
      component={Stack}
      role="row"
      direction="row"
      flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      alignItems="center"
      variant="body2"
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
      {...props}
    />
  )
}

export const BodyRowElement = fixedForwardRef(BodyRowElementInnner)
