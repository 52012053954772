import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'
import { dateToDayjs, dayjsToQueryVariable } from '../util/DateUtils'

const richlistQuery = graphql(`
  query richlist(
    $coin: String!
    $date: String!
    $richListCursor: RichListCursor
    $richListLimit: Int
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      date(date: $date) {
        richList(direction: DESC, cursor: $richListCursor, limit: $richListLimit) {
          ...RichListFragment
        }
      }
    }
  }
`)

export const richListLoader = (({ params: { coin, date } }) => {
  if (!coin) throw new Error('Param coin is required')
  return preloadQuery(richlistQuery, {
    variables: { coin, date: dayjsToQueryVariable(dateToDayjs(date)) }
  })
}) satisfies LoaderFunction
