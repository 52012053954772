import { useMemo } from 'react'

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
function formatBytes(bytes: number, decimals?: number) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024,
    dm = decimals || 2,
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function useFormattedSize(bytes: number, decimals?: number) {
  return useMemo(() => formatBytes(bytes, decimals), [bytes, decimals])
}
