import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../../ApolloClient'
import { graphql } from '../../gql'

const addressCandlesticksQuery = graphql(`
  query addressCandlesticks($coin: String!, $address: String!, $cursor: OHLCCursor, $limit: Int) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      address(address: $address) {
        address
        ohlc(direction: DESC, limit: $limit, cursor: $cursor) {
          items {
            timestamp
            open
            high
            low
            close
          }
          hasMore
        }
      }
    }
  }
`)

export const addressCandlesticksLoader = (({ params: { coin, address } }) => {
  if (!coin) throw new Error('Param coin is required')
  if (!address) throw new Error('Param coin is required')
  return preloadQuery(addressCandlesticksQuery, { variables: { coin, address } })
}) satisfies LoaderFunction
