import { Suspense, useCallback, useEffect, useTransition } from 'react'

import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack
} from '@mui/material'
import { ChevronLeft, ChevronRight, DataArray } from '@mui/icons-material'

import { BlockCardContent, BlockCardContentSkeleton } from './BlockCardContent'
import { useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import { BlockTransactionsByHashQuery, BlockTransactionsByHeightQuery } from '../gql/graphql'
import { blockLoader } from './blockLoader'
import { BlockTransactions, BlockTransactionsSkeleton } from './BlockTransactions'
import { CoinSymbolToName } from '../util/CoinUtil'
import { BlockCardHeaderAction } from './BlockCardHeaderAction'

type HeightData = Exclude<BlockTransactionsByHeightQuery['coinBySymbol'], null | undefined>

type HashData = Exclude<BlockTransactionsByHashQuery['coinBySymbol'], null | undefined>

const isHeightData = (data: HeightData | HashData): data is HeightData => {
  return 'blockByHeight' in data
}

function LoadedBlock() {
  const queryRef = useLoaderData<typeof blockLoader>()
  const [loading, startTransition] = useTransition()
  const { fetchMore } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery<BlockTransactionsByHashQuery | BlockTransactionsByHeightQuery>(
    queryRef
  )
  const handleFetchMore = useCallback(
    (txN: number) => {
      startTransition(() => {
        fetchMore({ variables: { cursor: { txN } } })
      })
    },
    [startTransition, fetchMore]
  )
  const coin = data.coinBySymbol
  if (!coin) throw new Error('Coin not found')
  const block = isHeightData(coin) ? coin.blockByHeight?.block : coin.block
  if (!block) throw new Error('BLOCK NOT FOUND')
  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader
          avatar={
            <Avatar>
              <DataArray />
            </Avatar>
          }
          title={`Block ${block.height}`}
          subheader={block.hash}
          action={<BlockCardHeaderAction block={block} />}
        />
        <CardContent>
          <BlockCardContent block={block} />
        </CardContent>
      </Card>
      <BlockTransactions block={block} handleFetchMore={handleFetchMore} loading={loading} />
    </Stack>
  )
}

function BlockSkeleton({ blockId }: { blockId: string }) {
  const isHeight = Number.isInteger(Number(blockId))
  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader
          avatar={
            <Avatar>
              <DataArray />
            </Avatar>
          }
          title={
            isHeight ? (
              `Block ${blockId}`
            ) : (
              <Box>
                Block <Skeleton width={56} sx={{ display: 'inline-block' }} />
              </Box>
            )
          }
          subheader={isHeight ? <Skeleton width={640} /> : blockId}
          action={
            <>
              <IconButton disabled>
                <ChevronLeft />
              </IconButton>
              <IconButton disabled>
                <ChevronRight />
              </IconButton>
            </>
          }
        ></CardHeader>
        <CardContent>
          <BlockCardContentSkeleton />
        </CardContent>
      </Card>
      <BlockTransactionsSkeleton />
    </Stack>
  )
}

function BlockPage() {
  const { coin, blockId } = useParams()
  if (!coin) throw new Error('Param coin is required')
  if (!blockId) throw new Error('Param blockId is required')
  useEffect(() => {
    const oldTitle = document.title
    document.title = `${CoinSymbolToName[coin.toUpperCase()]} Block ${blockId}`
    return () => {
      document.title = oldTitle
    }
  }, [coin, blockId])
  return (
    <Suspense fallback={<BlockSkeleton blockId={blockId} />}>
      <LoadedBlock />
    </Suspense>
  )
}

export default BlockPage
