import { useReadQuery } from '@apollo/client'
import { useRouteLoaderData } from 'react-router-typesafe'
import { RichListTable } from '../../rich-list/RichListTable'
import { coinPageLoader } from '../CoinPage'
import { RichListCardLayout } from './RichListCardLayout'

export function RichListCard() {
  const queryRef = useRouteLoaderData<typeof coinPageLoader>('coin_page')
  const { data } = useReadQuery(queryRef)
  if (!data.coinBySymbol) return null
  return (
    <RichListCardLayout
      content={
        <RichListTable
          query={data.coinBySymbol.date.richList}
          bip44_symbol={data.coinBySymbol.bip44_symbol}
          limit={5}
        />
      }
    />
  )
}
