import { Breadcrumbs } from '@mui/material'
import { UIMatch, useMatches } from 'react-router-dom'
import RouterLink from './RouterLink'

type Match = UIMatch<unknown, HandleType>

type HandleType = {
  crumb: React.ReactNode
  disableBreadcrumbLink?: boolean
}

export function AppBreadcrumbs() {
  const matches = useMatches() as Match[]
  const crumbMatches = matches.filter((match) => Boolean(match.handle?.crumb))
  return (
    <Breadcrumbs
      maxItems={5}
      sx={{ p: 1, visibility: crumbMatches.length < 2 ? 'hidden' : 'visible' }}
      aria-label="Breadcrumbs"
    >
      {crumbMatches.map((match, index) =>
        match.handle.disableBreadcrumbLink ? (
          <span key={index}>{match.handle.crumb}</span>
        ) : (
          <RouterLink
            key={index}
            to={match.pathname}
            color="inherit"
            underline="hover"
            aria-current={index === crumbMatches.length - 1 ? 'location' : undefined}
          >
            {match.handle.crumb}
          </RouterLink>
        )
      )}
    </Breadcrumbs>
  )
}
