import { ChevronLeft } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { Link } from 'react-router-dom'
import { FragmentType, graphql, getFragmentData } from '../gql'
import { ScriptPubkeyCell } from './TransactionOutput'

const TransactionInputFragment = graphql(`
  fragment TransactionInputFragment on ITransactionInput {
    coinbase
    txid
    vout
    spendingIndex
    spentOutput {
      ...ScriptPubkeyFragment
      value
    }
  }
`)

export const TransactionInputRow = ({
  input,
  highlightedInput,
  address,
  coin
}: {
  input: FragmentType<typeof TransactionInputFragment>
  highlightedInput?: number
  address?: string
  coin: string
}) => {
  const data = getFragmentData(TransactionInputFragment, input)
  return (
    <TableRow selected={data.spendingIndex === highlightedInput}>
      {data.coinbase ? (
        <>
          <TableCell sx={{ border: 'none' }}></TableCell>
          <TableCell colSpan={2}>Newly generated coins</TableCell>
        </>
      ) : (
        <>
          <TableCell sx={{ border: 'none', padding: 0 }}>
            {data.txid && (
              <IconButton
                component={Link}
                to={{
                  pathname: `../${data.txid}`,
                  search: `output=${data.vout}`
                }}
                relative="path"
                aria-label="Navigate to spent output"
              >
                <ChevronLeft />
              </IconButton>
            )}
          </TableCell>
          {data.spentOutput && (
            <ScriptPubkeyCell output={data.spentOutput} coin={coin} highlightedAddress={address} />
          )}

          <TableCell>
            {data.spentOutput?.value} {coin.toUpperCase()}
          </TableCell>
        </>
      )}
    </TableRow>
  )
}
