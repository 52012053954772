import { Box, BoxProps } from '@mui/material'
import { fixedForwardRef } from './fixedForwardRef'

type RowGroupElementInnerProps<C extends React.ElementType> = BoxProps<C, { component?: C }>

function RowGroupElementInner<C extends React.ElementType>(
  props: RowGroupElementInnerProps<C>,
  ref: React.Ref<C>
) {
  return <Box role="rowgroup" ref={ref} {...props} />
}

export const RowGroupElement = fixedForwardRef(RowGroupElementInner)
