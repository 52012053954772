import {
  Autocomplete,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  autocompleteClasses,
  InputAdornment,
  Stack
} from '@mui/material'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import { debounce } from '@mui/material/utils'
import { useLazyQuery } from '@apollo/client'
import { graphql } from './gql'
import { AccountBalanceWallet, DataArray } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

const searchStringQuery = graphql(`
  query searchString($search: String!) {
    coins {
      bip44_symbol
      name
      address(address: $search) {
        address
        addressBlocks(direction: DESC, limit: 1) {
          items {
            address {
              address
            }
            height
            balance
          }
        }
        unconfirmedBalanceChange
        unconfirmedTxCount
      }
      transaction(txid: $search) {
        txid
      }
      block(hash: $search) {
        hash
        height
        txCount
      }
    }
  }
`)

const searchIntQuery = graphql(`
  query searchInt($search: Int!) {
    coins {
      bip44_symbol
      name
      blockByHeight(height: $search) {
        height
        block {
          hash
          height
          txCount
        }
      }
    }
  }
`)

interface BaseResult {
  type: string
  coinName: string
  coinSymbol: string
}

interface AddressResult extends BaseResult {
  address: string
  balance: number
  type: 'address'
}

interface TransactionResult extends BaseResult {
  txid: string
  type: 'transaction'
}

interface BlockResult extends BaseResult {
  height: number
  hash: string
  txCount: number
  type: 'block'
}

type SearchResult = AddressResult | TransactionResult | BlockResult

type AddressItemProps = {
  item: AddressResult
  listProps: React.HTMLAttributes<HTMLLIElement>
}

const AddressItem = forwardRef<HTMLLIElement, AddressItemProps>(function AddressItem(
  { item, listProps }: AddressItemProps,
  ref
) {
  return (
    <ListItem {...listProps} ref={ref}>
      <ListItemIcon>
        <AccountBalanceWallet />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            Address{' '}
            <Typography
              component="span"
              variant="inherit"
              color="text.secondary"
              sx={{ display: 'inline' }}
            >
              ({item.balance} {item.coinSymbol})
            </Typography>
          </>
        }
        secondary={item.address}
      />
    </ListItem>
  )
})

type TransactionItemProps = {
  item: TransactionResult
  listProps: React.HTMLAttributes<HTMLLIElement>
}

const TransactionItem = forwardRef<HTMLLIElement, TransactionItemProps>(function TransactionItem(
  { item, listProps }: TransactionItemProps,
  ref
) {
  return (
    <ListItem {...listProps} ref={ref}>
      <ListItemIcon>
        <AccountBalanceWallet />
      </ListItemIcon>
      <ListItemText primary={<>Transaction</>} secondary={item.txid} />
    </ListItem>
  )
})

type BlockItemProps = {
  item: BlockResult
  listProps: React.HTMLAttributes<HTMLLIElement>
}

const BlockItem = forwardRef<HTMLLIElement, BlockItemProps>(function BlockItem(
  { item, listProps }: BlockItemProps,
  ref
) {
  return (
    <ListItem {...listProps} ref={ref}>
      <ListItemIcon>
        <DataArray />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            Block{' '}
            <Typography
              component="span"
              variant="inherit"
              color="text.secondary"
              sx={{ display: 'inline' }}
            >
              #{item.height}
            </Typography>
          </>
        }
        secondary={item.hash}
      />
    </ListItem>
  )
})

function Search() {
  const [value, setValue] = useState<SearchResult | null>(null)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<SearchResult[]>([])
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [query] = useLazyQuery(searchStringQuery)
  const [queryInt] = useLazyQuery(searchIntQuery)
  const navigate = useNavigate()
  const fetch = useMemo(
    () =>
      debounce((searchStr: string, callback: (results: SearchResult[]) => void) => {
        if (searchStr === '') {
          callback([])
          return
        }
        const asNumber = Number(searchStr)
        if (Number.isInteger(asNumber)) {
          queryInt({ variables: { search: asNumber } }).then((results) => {
            const res: SearchResult[] = []
            results.data?.coins.forEach((e) => {
              const { blockByHeight, name: coinName, bip44_symbol: coinSymbol } = e
              if (blockByHeight) {
                res.push({
                  type: 'block',
                  height: blockByHeight.block.height,
                  hash: blockByHeight.block.hash,
                  txCount: blockByHeight.block.txCount,
                  coinName,
                  coinSymbol
                })
              }
            })
            callback(res)
          })
          return
        }
        query({ variables: { search: searchStr } }).then((results) => {
          const res: SearchResult[] = []
          results.data?.coins.forEach((e) => {
            const { address, transaction, block, name: coinName, bip44_symbol: coinSymbol } = e
            if (address) {
              if (address.addressBlocks.items.length > 0 || address.unconfirmedTxCount > 0) {
                const balance =
                  (address.addressBlocks.items[0]?.balance || 0) +
                  (address.unconfirmedBalanceChange || 0)
                res.push({
                  address: address.address,
                  balance,
                  coinName,
                  coinSymbol,
                  type: 'address'
                })
              }
            }
            if (transaction?.txid) {
              res.push({
                txid: transaction.txid,
                coinName,
                coinSymbol,
                type: 'transaction'
              })
            }
            if (block?.hash) {
              res.push({
                height: block.height,
                hash: block.hash,
                txCount: block.txCount,
                coinName,
                coinSymbol,
                type: 'block'
              })
            }
          })
          callback(res)
        })
      }, 400),
    [query, queryInt]
  )

  useEffect(() => {
    if (!open) {
      setOptions([])
      setValue(null)
    }
  }, [open])

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return
    }
    let active = true
    setLoading(true)
    fetch(inputValue, (res) => {
      if (active) {
        setOptions(res)
      }
      setLoading(false)
    })

    return () => {
      setLoading(false)
      active = false
    }
  }, [inputValue, value, fetch])

  return (
    <Stack direction="row" justifyContent="center">
      <Autocomplete
        id="Search"
        sx={{ flex: '0 1 640px' }}
        ListboxProps={{
          sx: {
            [`& .${autocompleteClasses.groupLabel}`]: {
              backgroundColor: 'transparent'
            }
          }
        }}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        isOptionEqualToValue={(option, value) => {
          if (option.coinSymbol !== value.coinSymbol) return false
          switch (option.type) {
            case 'address':
              return value.type === 'address' && option.address === value.address
            case 'transaction':
              return value.type === 'transaction' && option.txid === value.txid
            case 'block':
              return value.type === 'block' && option.hash === value.hash
          }
        }}
        getOptionKey={(option) => {
          const id = (): string => {
            switch (option.type) {
              case 'address':
                return option.address
              case 'transaction':
                return option.txid
              case 'block':
                return `${option.height}`
            }
          }
          return `${option.coinSymbol}-${id()}`
        }}
        getOptionLabel={(option) => {
          switch (option.type) {
            case 'address':
              return option.address
            case 'transaction':
              return option.txid
            case 'block':
              return `${option.height}`
          }
        }}
        groupBy={(option) => option.coinName}
        componentsProps={{ paper: { elevation: 8 } }}
        renderOption={(props, option, state) => {
          const { key, ...rest } = props as typeof props & { key: string }
          switch (option.type) {
            case 'address':
              return <AddressItem item={option} listProps={rest} key={key} />
            case 'transaction':
              return <TransactionItem listProps={rest} item={option} key={key} />
            case 'block':
              return <BlockItem listProps={rest} item={option} key={key} />
          }
        }}
        options={options}
        loading={loading}
        includeInputInList
        filterOptions={(x) => x}
        noOptionsText="Not found"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
          if (newValue === null) return
          switch (newValue.type) {
            case 'address':
              navigate(`/${newValue.coinSymbol.toLowerCase()}/address/${newValue.address}`)
              break
            case 'transaction':
              navigate(`/${newValue.coinSymbol.toLowerCase()}/transaction/${newValue.txid}`)
              break
            case 'block':
              navigate(`/${newValue.coinSymbol.toLowerCase()}/blocks/${newValue.hash}`)
              break
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Search"
              size="small"
              color="primary"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 40,
                  backgroundColor: (theme) => theme.palette.background.paper
                },
                endAdornment: (
                  <InputAdornment position="end">
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  </InputAdornment>
                )
              }}
            />
          )
        }}
      />
    </Stack>
  )
}

export default Search
