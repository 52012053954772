import { styled } from '@mui/material'
import { CellElement } from '../common/table/CellElement'
import { HeaderRowElement } from '../common/table/RowElement'
import { RowGroupElement } from '../common/table/RowGroupElement'
import { TableElement } from '../common/table/TableElement'
import { FragmentType, getFragmentData, graphql } from '../gql'
import RouterLink, { RouterLinkProps } from '../RouterLink'
import { UnconfirmedTransactionsTableRow } from './UnconfirmedTransactionsTableRow'

export const UnconfirmedTransactionsTableFragment = graphql(`
  fragment UnconfirmedTransactionsTableFragment on PaginatedIUnconfirmedTransactionResponse {
    items {
      txid
      ...UnconfirmedTransactionFragment
    }
    hasMore
  }
`)

interface Props {
  query: FragmentType<typeof UnconfirmedTransactionsTableFragment>
  bip44_symbol: string
  limit?: number
}

const TxidCell = styled(CellElement)(() => ({
  flex: '1 5 530px'
}))

export const TimeCell = styled(CellElement)(() => ({
  flex: '1 1 200px'
}))

export const InputsCell = styled(CellElement)(() => ({
  flex: '1 1 100px'
}))

export const OutputsCell = styled(CellElement)(() => ({
  flex: '1 1 100px'
}))

export const TxidLinkCell = styled(TxidCell)<{ component: typeof RouterLink } & RouterLinkProps>(
  () => ({
    overflowWrap: 'anywhere'
  })
)

export function UnconfirmedTransactionsTable({ query, bip44_symbol, limit }: Props) {
  const { items } = getFragmentData(UnconfirmedTransactionsTableFragment, query)
  return (
    <TableElement aria-label="Unconfirmed transactions">
      <RowGroupElement>
        <HeaderRowElement>
          <TimeCell>Time</TimeCell>
          <TxidCell>Txid</TxidCell>
          <InputsCell>Inputs</InputsCell>
          <OutputsCell>Outputs</OutputsCell>
        </HeaderRowElement>
      </RowGroupElement>
      <RowGroupElement>
        {items.slice(0, limit).map((e) => (
          <UnconfirmedTransactionsTableRow item={e} key={e.txid} bip44_symbol={bip44_symbol} />
        ))}
      </RowGroupElement>
    </TableElement>
  )
}
