import { useReadQuery } from '@apollo/client'
import { useRouteLoaderData } from 'react-router-typesafe'
import { useMempoolPoller } from '../../mempool/mempoolPoller'
import { UnconfirmedTransactionsTable } from '../../mempool/UnconfirmedTransactionsTable'
import { coinPageLoader } from '../CoinPage'
import { UnconfirmedTransactionsCardLayout } from './UnconfirmedTransactionsCardLayout'

export function UnconfirmedTransactionsCard() {
  const queryRef = useRouteLoaderData<typeof coinPageLoader>('coin_page')
  useMempoolPoller()
  const { data } = useReadQuery(queryRef)
  const coin = data.coinBySymbol
  if (!coin) return null
  return (
    <UnconfirmedTransactionsCardLayout
      content={
        <UnconfirmedTransactionsTable
          query={coin.mempool.transactions}
          bip44_symbol={coin.bip44_symbol}
          limit={5}
        />
      }
    />
  )
}
