import { Launch } from '@mui/icons-material'
import { Card, CardContent, CardHeader, IconButton } from '@mui/material'
import RouterLink from '../../RouterLink'

export function BlocksCardLayout(props: { content: React.ReactNode }) {
  return (
    <Card>
      <CardHeader
        title="Latest blocks"
        action={
          <IconButton component={RouterLink} to={`blocks`}>
            <Launch />
          </IconButton>
        }
      />
      <CardContent>{props.content}</CardContent>
    </Card>
  )
}
