import { Stack, useForkRef } from '@mui/material'
import { BaseSingleInputFieldProps, DateValidationError, FieldSection } from '@mui/x-date-pickers'
import { Dayjs } from 'dayjs'
import { forwardRef, useMemo } from 'react'

export const CustomDatePickerField = forwardRef(
  (
    props: BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, true, DateValidationError>,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { InputProps: { ref: inputpropsRef, startAdornment, endAdornment } = {} } = props
    const handleRef = useForkRef(inputpropsRef, ref)
    const valueStr = useMemo(() => props.value?.format('L'), [props.value])
    return (
      <Stack ref={handleRef} direction="row">
        {startAdornment}
        {valueStr}
        {endAdornment}
      </Stack>
    )
  }
)
