import { useReadQuery } from '@apollo/client'
import { Skeleton } from '@mui/material'
import { Suspense } from 'react'
import { useRouteLoaderData } from 'react-router-typesafe'
import { coinLoader } from './coinLoader'

const CoinCrumbInner = () => {
  const queryRef = useRouteLoaderData<typeof coinLoader>('coin')
  const { data } = useReadQuery(queryRef)
  return <>{data.coinBySymbol?.name}</>
}

export const CoinCrumb = () => {
  return (
    <Suspense fallback={<Skeleton width={60} />}>
      <CoinCrumbInner />
    </Suspense>
  )
}
