import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { LoaderFunction, useParams } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'

const blocksQuery = graphql(`
  query blocks($coin: String!, $cursor: BlockHashCursor) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      blocks(direction: DESC, cursor: $cursor) {
        ...BlocksTableFragment
        ...BlocksLoadMoreFragment
      }
    }
  }
`)

const blocksPollQuery = graphql(`
  query blocksPoller($coin: String!, $cursor: BlockHashCursor) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      blocks(direction: ASC, cursor: $cursor) {
        items {
          height
          hash
          block {
            height
            hash
            txCount
            size
            time
          }
        }
      }
    }
  }
`)

export const useBlocksPoller = (height: number) => {
  const { coin } = useParams()
  if (!coin) throw new Error('Coin param is required')
  const args = useMemo(() => {
    return {
      pollInterval: 5000,
      fetchPolicy: 'network-only' as const,
      variables: { coin, cursor: { height } }
    }
  }, [height, coin])
  const [query] = useLazyQuery(blocksPollQuery, args)
  useEffect(() => {
    const timer = setTimeout(() => {
      query()
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [query])
}

export const blocksLoader = (({ params: { coin } }) => {
  if (!coin) throw new Error('Param coin is required')
  return preloadQuery(blocksQuery, { variables: { coin } })
}) satisfies LoaderFunction
