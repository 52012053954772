import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@mui/lab'
import { Skeleton, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { BalanceChange } from '../../BalanceChange'
import { FragmentType, graphql, getFragmentData } from '../../gql'
import RouterLink from '../../RouterLink'
import { TimelineTransactions } from './TimelineTransactions'

const AddressBlockFragment = graphql(`
  fragment AddressBlockFragment2 on IAddressBlock {
    height
    balance
    balanceChange
    txCount
    blockHash {
      block {
        hash
        time
      }
    }
    transactions(direction: ASC, limit: 3) {
      ...AddressTransactionsFragment
    }
  }
`)

const formatDiff: Record<number, string> = {
  0: 'Today',
  1: 'Yesterday'
}

function DateItem({ blockTime }: { blockTime: dayjs.Dayjs }) {
  const [daysDiff, setDaysDiff] = useState(
    dayjs().startOf('day').diff(blockTime.startOf('day'), 'day')
  )

  const formattedDate = useMemo(
    () => formatDiff[daysDiff] ?? blockTime.format('L'),
    [blockTime, daysDiff]
  )

  useEffect(() => {
    if (daysDiff > 1) return //Display only today and yesterday in relative time
    const startOfTomorrow = dayjs().add(1, 'day').startOf('day')
    const diff = startOfTomorrow.diff(dayjs())
    const timer = setTimeout(() => {
      setDaysDiff(startOfTomorrow.diff(blockTime.startOf('day'), 'day'))
    }, diff)
    return () => {
      clearTimeout(timer)
    }
  }, [blockTime, daysDiff])

  return (
    <TimelineItem>
      <TimelineOppositeContent />
      <TimelineSeparator>
        <TimelineConnector />
        <Typography>{formattedDate}</Typography>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent />
    </TimelineItem>
  )
}

export function AddressTimelineItem(props: {
  query: FragmentType<typeof AddressBlockFragment>
  address: string
  disableEndConnector: boolean
  changeDay: boolean
}) {
  const res = getFragmentData(AddressBlockFragment, props.query)
  const { coin } = useParams()
  if (!coin) throw new Error('Param coin is required')
  const formattedTime = useMemo(
    () => dayjs(res.blockHash.block.time).format('LT'),
    [res.blockHash.block.time]
  )

  return (
    <>
      {props.changeDay && <DateItem blockTime={dayjs(res.blockHash.block.time)} />}
      <TimelineItem>
        <TimelineOppositeContent>
          {res.txCount > 1 && (
            <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
              {res.txCount} transactions (
              <BalanceChange
                balanceChange={res.balanceChange}
                component="span"
                variant="inherit"
              />{' '}
              {coin.toUpperCase()})
            </Typography>
          )}
          <TimelineTransactions
            query={res.transactions}
            height={res.height}
            address={props.address}
          />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color={res.balanceChange < 0 ? 'error' : 'success'} />
          {!props.disableEndConnector && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightMedium }}>
            {formattedTime} • Block{' '}
            <RouterLink
              to={generatePath('/:coin/blocks/:blockId', {
                coin,
                blockId: res.blockHash.block.hash
              })}
            >
              {res.height}
            </RouterLink>
          </Typography>

          <Typography>
            Balance{' '}
            <Typography component={'span'}>
              {res.balance} {coin.toUpperCase()}
            </Typography>
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </>
  )
}

export const AddressTimelineSkeletonItem = () => {
  return (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <Skeleton width={50} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent />
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Skeleton sx={{ flex: '0 1 300px' }} />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Stack direction={'row'} justifyContent="flex-end">
            <Skeleton sx={{ flex: '0 1 200px' }} />
          </Stack>
          <Stack direction={'row'} justifyContent="flex-end">
            <Skeleton sx={{ flex: '0 1 150px' }} />
          </Stack>
        </TimelineContent>
      </TimelineItem>
    </>
  )
}
