import { useQueryRefHandlers, useReadQuery } from '@apollo/client'
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  useTheme
} from '@mui/material'
import { alpha } from '@mui/system'
import dayjs from 'dayjs'
import {
  createChart,
  CandlestickData,
  Time,
  WhitespaceData,
  LogicalRangeChangeEventHandler,
  ISeriesApi,
  IChartApi,
  MouseEventParams,
  TimeRangeChangeEventHandler,
  SizeChangeEventHandler
} from 'lightweight-charts'
import { Suspense, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { useLoaderData } from 'react-router-typesafe'
import { addressCandlesticksLoader } from './addressCandlesticksLoader'
import utc from 'dayjs/plugin/utc'
import { useParams } from 'react-router-dom'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'

dayjs.extend(utc)

const Legend = ({ data }: { data: Omit<CandlestickData, 'time'> }) => {
  const { open, high, low, close } = data
  const getColor = useCallback(
    (theme: Theme) => {
      if (open === close) return theme.palette.text.primary
      return open < close ? theme.palette.success.main : theme.palette.error.main
    },
    [open, close]
  )

  return (
    <List
      disablePadding
      sx={{
        fontSize: 12,
        fontFamily: 'monospace',
        color: (theme) => getColor(theme),
        p: 1
      }}
    >
      <ListItem disablePadding>O: {open}</ListItem>
      <ListItem disablePadding>H: {high}</ListItem>
      <ListItem disablePadding>L: {low}</ListItem>
      <ListItem disablePadding>C: {close}</ListItem>
    </List>
  )
}

const AddressCandleStickInner = () => {
  const { coin } = useParams()
  if (!coin) throw new Error('Param coin is required')
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const [isLoading, startTransition] = useTransition()
  const queryRef = useLoaderData<typeof addressCandlesticksLoader>()
  const { fetchMore } = useQueryRefHandlers(queryRef)
  const { data } = useReadQuery(queryRef)
  const handleFetchMore = useCallback(
    (args: Parameters<typeof fetchMore>[0]) => {
      return new Promise<Awaited<ReturnType<typeof fetchMore>>>((resolve, reject) => {
        startTransition(() => {
          fetchMore(args).then(resolve, reject)
        })
      })
    },
    [fetchMore]
  )

  const hasMore = Boolean(data?.coinBySymbol?.address.ohlc.hasMore)
  const items = data.coinBySymbol?.address.ohlc.items

  const cursor = useMemo(() => {
    return items && items.length > 0 ? { timestamp: items[items.length - 1].timestamp } : undefined
  }, [items])

  const theme = useTheme()

  const candleStickData = useMemo(() => {
    return [...(items || [])].reverse().flatMap(({ timestamp, ...item }, index, arr) => {
      const res: (CandlestickData<Time> | WhitespaceData<Time>)[] = [
        { time: dayjs.utc(timestamp).format('YYYY-MM-DD'), ...item }
      ]
      if (index === arr.length - 1 && item.close === 0) {
        return res
      }
      const target = index === arr.length - 1 ? dayjs.utc() : dayjs.utc(arr[index + 1].timestamp)
      for (
        let day = dayjs.utc(timestamp).add(1, 'day');
        day.isBefore(target);
        day = day.add(1, 'day')
      ) {
        //Add whitespace for missing days
        res.push({
          time: day.format('YYYY-MM-DD'),
          open: item.close,
          high: item.close,
          low: item.close,
          close: item.close
        })
      }
      return res
    })
  }, [items])

  const chartDateRange = useMemo(() => {
    if (candleStickData.length === 0) return undefined
    const first = candleStickData[0].time
    const last = candleStickData[candleStickData.length - 1].time
    if (typeof first !== 'string') return undefined
    if (typeof last !== 'string') return undefined
    return { from: dayjs.utc(first, 'YYYY-MM-DD'), to: dayjs.utc(last, 'YYYY-MM-DD') }
  }, [candleStickData])

  const chartRef = useRef<IChartApi>()
  const candleStickSeriesRef = useRef<ISeriesApi<'Candlestick', Time>>()

  const [howeredData, setHoweredData] = useState<CandlestickData<Time>>()

  useEffect(() => {
    if (chartContainerRef.current === null) return
    const chart = createChart(chartContainerRef.current, {
      timeScale: {
        minBarSpacing: 0.01
      },
      handleScroll: {},
      handleScale: { axisPressedMouseMove: false, axisDoubleClickReset: false },
      localization: {
        priceFormatter: (price: number) =>
          (Number.isInteger(price) ? price : price.toFixed(2)) + ' ' + coin.toUpperCase()
      },
      layout: {
        fontFamily: "'Roboto', sans-serif",
        background: { color: 'transparent' }
      }
    })
    chart.timeScale().fitContent()
    chartRef.current = chart
    const series = chart.addCandlestickSeries({
      lastValueVisible: false,
      priceLineVisible: false
    })
    candleStickSeriesRef.current = series
    const croshairMoveHandler = ({ seriesData }: MouseEventParams<Time>) => {
      const data = seriesData.get(series) as CandlestickData<Time> | undefined
      setHoweredData(data)
    }
    chart.subscribeCrosshairMove(croshairMoveHandler)
    series.priceScale().applyOptions({ scaleMargins: { bottom: 0.02, top: 0.02 } })
    const handleResize = () => {
      if (chartContainerRef.current === null) return
      chart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      chart.unsubscribeCrosshairMove(croshairMoveHandler)
      window.removeEventListener('resize', handleResize)
      chartRef.current = undefined
      candleStickSeriesRef.current = undefined
      chart.remove()
    }
  }, [coin])

  useEffect(() => {
    if (candleStickSeriesRef.current) {
      candleStickSeriesRef.current.applyOptions({
        upColor: theme.palette.success.main,
        downColor: theme.palette.error.main,
        borderUpColor: theme.palette.success.main,
        borderDownColor: theme.palette.error.main,
        wickUpColor: theme.palette.success.main,
        wickDownColor: theme.palette.error.main
      })
    }
    if (chartRef.current) {
      chartRef.current.applyOptions({
        layout: {
          textColor: theme.palette.text.primary,
          fontSize: theme.typography.fontSize
        },
        rightPriceScale: {
          textColor: theme.palette.text.primary,
          borderColor: theme.palette.divider
        },
        timeScale: {
          borderColor: theme.palette.divider
        },
        crosshair: {
          horzLine: {
            color: theme.palette.primary.dark,
            labelBackgroundColor: theme.palette.background.default
          },
          vertLine: {
            color: theme.palette.primary.dark,
            labelBackgroundColor: theme.palette.background.default
          }
        },
        grid: {
          vertLines: {
            color: theme.palette.divider
          },
          horzLines: {
            color: theme.palette.divider
          }
        }
      })
    }
  }, [theme])

  useEffect(() => {
    const currentChart = chartRef.current
    const currentSeries = candleStickSeriesRef.current
    if (!currentSeries || !currentChart) return
    currentSeries.setData(candleStickData)
  }, [candleStickData])

  useEffect(() => {
    if (isLoading) return
    const currentChart = chartRef.current
    if (!currentChart || !hasMore) return

    const logicalRangeHandler: LogicalRangeChangeEventHandler = (timeRange) => {
      if (timeRange === null) return

      // When chart is loading, timeRange.from and timeRange.to are integers
      if (Number.isInteger(timeRange.from)) return

      if (timeRange.from < -1) {
        handleFetchMore({ variables: { cursor, limit: 100 } })
      }
    }
    currentChart.timeScale().subscribeVisibleLogicalRangeChange(logicalRangeHandler)
    return () => {
      currentChart.timeScale().unsubscribeVisibleLogicalRangeChange(logicalRangeHandler)
    }
  }, [candleStickData, hasMore, cursor, handleFetchMore, isLoading])

  const [zoomLevel, setZoomLevel] = useState<string>()

  useEffect(() => {
    const currentChart = chartRef.current
    if (!currentChart) return
    if (!chartDateRange) return
    const timeRangeHandler: TimeRangeChangeEventHandler<Time> = (timeRange) => {
      if (timeRange === null) return
      if (typeof timeRange.from !== 'string' || typeof timeRange.to !== 'string') return
      const { from, to } = timeRange
      const fromDay = dayjs.utc(from, 'YYYY-MM-DD')
      const toDay = dayjs.utc(to, 'YYYY-MM-DD')
      if (!toDay.isSame(chartDateRange.to)) {
        setZoomLevel(undefined)
      } else if (fromDay.isSame(chartDateRange.to.subtract(1, 'week').add(1, 'day'))) {
        setZoomLevel('week')
      } else if (fromDay.isSame(chartDateRange.to.subtract(1, 'month').add(1, 'day'))) {
        setZoomLevel('month')
      } else if (fromDay.isSame(chartDateRange.to.subtract(1, 'year').add(1, 'day'))) {
        setZoomLevel('year')
      } else if (!hasMore && fromDay.isSame(chartDateRange.from)) {
        setZoomLevel('all')
      } else {
        setZoomLevel(undefined)
      }
    }
    currentChart.timeScale().subscribeVisibleTimeRangeChange(timeRangeHandler)
    return () => {
      currentChart.timeScale().unsubscribeVisibleTimeRangeChange(timeRangeHandler)
    }
  }, [chartDateRange, hasMore])

  const allZoomed = zoomLevel === 'all'
  useEffect(() => {
    if (!allZoomed) return
    if (!chartDateRange) return
    const currentChart = chartRef.current
    if (!currentChart) return
    const timeScaleWidthChangeHandler: SizeChangeEventHandler = (width, height) => {
      chartRef.current?.timeScale().setVisibleRange({
        from: chartDateRange.from.format('YYYY-MM-DD'),
        to: chartDateRange.to.format('YYYY-MM-DD')
      })
    }
    currentChart.timeScale().subscribeSizeChange(timeScaleWidthChangeHandler)
    return () => {
      currentChart.timeScale().subscribeSizeChange(timeScaleWidthChangeHandler)
    }
  }, [allZoomed, chartDateRange])

  const [allLoading, setAllLoading] = useState(false)
  useEffect(() => {
    if (!allLoading) return
    if (!chartDateRange) return
    if (isLoading) return
    if (hasMore) {
      handleFetchMore({ variables: { cursor, limit: 1000 } })
    } else {
      setAllLoading(false)
      chartRef.current?.timeScale().setVisibleRange({
        from: chartDateRange.from.format('YYYY-MM-DD'),
        to: chartDateRange.to.format('YYYY-MM-DD')
      })
    }
  }, [hasMore, allLoading, handleFetchMore, cursor, chartDateRange, isLoading])

  const handleRequestZoom = useMemo<ToggleButtonProps['onChange']>(() => {
    if (!chartDateRange) return undefined
    return (_, value: dayjs.ManipulateType | 'all' | null) => {
      if (!chartRef.current) return
      if (value === 'all' || (value === null && zoomLevel === 'all')) {
        if (hasMore) {
          setAllLoading(true)
        }
        chartRef.current.timeScale().setVisibleRange({
          from: chartDateRange.from.format('YYYY-MM-DD'),
          to: chartDateRange.to.format('YYYY-MM-DD')
        })
      } else if (value !== null) {
        chartRef.current.timeScale().setVisibleRange({
          from: chartDateRange.to.subtract(1, value).add(1, 'day').format('YYYY-MM-DD'),
          to: chartDateRange.to.format('YYYY-MM-DD')
        })
      }
    }
  }, [chartDateRange, hasMore, zoomLevel])

  const [fullscreenLoading, setFullscreenLoading] = useState(false)
  const [isFullscreen, setFullscreen] = useState(document.fullscreenElement !== null)

  useEffect(() => {
    const handler = () => setFullscreen(document.fullscreenElement !== null)
    document.addEventListener('fullscreenchange', handler)
    return () => document.removeEventListener('fullscreenchange', handler)
  }, [])

  const parentRef = useRef<HTMLDivElement>(null)

  const toggleFullScreen = useCallback(async () => {
    if (parentRef.current) {
      setFullscreenLoading(true)
      const visibleRange = chartRef.current?.timeScale().getVisibleRange()
      try {
        if (isFullscreen) await document.exitFullscreen()
        else await parentRef.current.requestFullscreen()
      } catch (e) {
      } finally {
        if (chartRef.current && chartContainerRef.current) {
          chartRef.current.resize(
            chartContainerRef.current.clientWidth,
            chartContainerRef.current.clientHeight
          )
          if (visibleRange) chartRef.current.timeScale().setVisibleRange(visibleRange)
        }
        setFullscreenLoading(false)
      }
    }
  }, [isFullscreen])

  return (
    <Stack
      position="relative"
      sx={{
        backgroundColor: isFullscreen ? theme.palette.background.paper : 'transparent',
        height: 500
      }}
      ref={parentRef}
    >
      {isLoading && (
        <Box
          sx={{
            pointerEvents: 'none',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            position: 'absolute',
            zIndex: 5,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box ref={chartContainerRef} sx={{ flex: '1 1 auto', overflow: 'hidden' }}></Box>
      {howeredData && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            zIndex: 4,
            background: (theme) => alpha(theme.palette.background.default, 0.7)
          }}
        >
          <Legend data={howeredData} />
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 4
        }}
      >
        <IconButton onClick={toggleFullScreen} disabled={fullscreenLoading}>
          {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
        </IconButton>
      </Box>
      <ToggleButtonGroup
        value={zoomLevel}
        exclusive
        onChange={handleRequestZoom}
        size="small"
        color="primary"
      >
        <ToggleButton
          value="week"
          disabled={!chartDateRange || chartDateRange.to.diff(chartDateRange.from, 'week') < 1}
        >
          Week
        </ToggleButton>
        <ToggleButton
          value="month"
          disabled={!chartDateRange || chartDateRange.to.diff(chartDateRange.from, 'month') < 1}
        >
          Month
        </ToggleButton>
        <ToggleButton
          value="year"
          disabled={!chartDateRange || chartDateRange.to.diff(chartDateRange.from, 'year') < 1}
        >
          Year
        </ToggleButton>
        <ToggleButton value="all">All</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}

export const AddressCandleStick = () => {
  return (
    <Suspense
      fallback={
        <Stack sx={{ height: 300 }} alignItems="center" justifyContent="center">
          <LinearProgress sx={{ width: 100 }} />
        </Stack>
      }
    >
      <AddressCandleStickInner />
    </Suspense>
  )
}
