import { LoaderFunction } from 'react-router-dom'
import { preloadQuery } from '../ApolloClient'
import { graphql } from '../gql'

const transactionQuery = graphql(`
  query transaction(
    $coin: String!
    $txid: String!
    $inputCursor: TransactionInputCursor
    $outputCursor: TransactionOutputCursor
  ) {
    coinBySymbol(symbol: $coin) {
      bip44_symbol
      transaction(txid: $txid) {
        txid
        ...TransactionDetails
        ...TransactionInputsFragment
        ...TransactionOutputsFragment
      }
    }
  }
`)

export const transactionLoader = (({ params: { coin, txid } }) => {
  if (!coin) throw new Error('Param coin is required')
  if (!txid) throw new Error('Param txid is required')
  return preloadQuery(transactionQuery, { variables: { coin, txid } })
}) satisfies LoaderFunction
