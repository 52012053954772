import { styled } from '@mui/material'
import dayjs from 'dayjs'
import { CellElement } from '../common/table/CellElement'
import { BodyRowElement, HeaderRowElement } from '../common/table/RowElement'
import { RowGroupElement } from '../common/table/RowGroupElement'
import { TableElement } from '../common/table/TableElement'
import { FragmentType, getFragmentData, graphql } from '../gql'
import RouterLink, { RouterLinkProps } from '../RouterLink'

export const RichListTableFragment = graphql(`
  fragment RichListFragment on PaginatedIRichlistResponse {
    items {
      address {
        address
        addressBlocks(limit: 1, direction: ASC) {
          items {
            height
            address {
              address
            }
            blockHash {
              height
              block {
                hash
                time
              }
            }
          }
        }
      }
      balance
      balanceChange
    }
    hasMore
  }
`)

interface Props {
  query: FragmentType<typeof RichListTableFragment>
  bip44_symbol: string
  limit?: number
}

const BalanceCell = styled(CellElement)(() => ({
  flex: '1 2 200px'
}))

const AddressCell = styled(CellElement)(() => ({
  flex: '1 5 500px'
}))

const AddressLinkCell = styled(AddressCell)<{ component: typeof RouterLink } & RouterLinkProps>(
  () => ({
    overflowWrap: 'anywhere'
  })
)

const FirstSeenCell = styled(CellElement)(() => ({
  flex: '1 2 110px'
}))

export function RichListTable({ query, bip44_symbol, limit }: Props) {
  const { items } = getFragmentData(RichListTableFragment, query)
  return (
    <TableElement aria-label="Rich list">
      <RowGroupElement>
        <HeaderRowElement>
          <BalanceCell role="columnheader">Balance</BalanceCell>
          <AddressCell role="columnheader">Address</AddressCell>
          <FirstSeenCell role="columnheader">First seen</FirstSeenCell>
        </HeaderRowElement>
      </RowGroupElement>
      <RowGroupElement>
        {items.slice(0, limit).map((item) => {
          return (
            <BodyRowElement key={item.address.address}>
              <BalanceCell role="cell">
                {item.balance} {bip44_symbol}
              </BalanceCell>
              <AddressLinkCell
                component={RouterLink}
                to={`/${bip44_symbol.toLowerCase()}/address/${item.address.address}`}
                underline="none"
                role="cell"
              >
                {item.address.address}
              </AddressLinkCell>
              <FirstSeenCell role="cell">
                {dayjs(item.address.addressBlocks.items[0].blockHash.block.time).format('L')}
              </FirstSeenCell>
            </BodyRowElement>
          )
        })}
      </RowGroupElement>
    </TableElement>
  )
}
