import { Theme, Typography, TypographyProps } from '@mui/material'

type Props = {
  balanceChange: number
} & TypographyProps

const getSign = (balanceChange: number) => {
  if (balanceChange === 0) return ''
  return balanceChange > 0 ? '+' : '-'
}

type Sign = ReturnType<typeof getSign>

const getColor = (sign: Sign, theme: Theme) => {
  if (sign === '') return undefined
  return sign === '+' ? theme.palette.success.main : theme.palette.error.main
}

export const BalanceChange = ({ balanceChange, ...rest }: Props) => {
  const sign = getSign(balanceChange)
  return (
    <Typography color={(theme) => getColor(sign, theme)} {...rest}>
      {sign}
      {Math.abs(balanceChange)}
    </Typography>
  )
}
